export default function ProductValidator(type, validationData) {
    this.id = null;
    this.name = null;
    this.price = null;
    this.priceIncl = null;
    this.vat = null;
    this.vatGroupId = null;
    this.metadata = {};

    if(type === 'product') {
        this.isCombination = false;
        this.isCardAvailable = false;
        this.isPersonalizationAvailable = false;
        this.isPersonalizationRequired = false;
        this.isCardAvailable = false;
        this.isLetterAvailable = false;
    }

    let errors = [];

    let labels = {
        card: {
            label: 'kaartje',
            article: 'het'
        },
        personalization: {
            label: 'personalisatie',
            article: 'de'
        },
        letter: {
            label: 'brief',
            article: 'de'
        },
        product: {
            label: 'product',
            article: 'het'
        },
    };

    this.set = function(key, value) {
        this[key] = value;

        this.validate();
    };

    this.get = function (key) {
        return this[key] || null;
    };

    this.validate = function() {
        errors = [];

        const messagePrefix = `${labels[type].article} ${labels[type].label}`;

        if(this.id === null) {
            errors.push(`${capitalizeFilter(messagePrefix)} kan niet worden verwerkt (ID ontbreekt).`);
        }

        if(this.name === null) {
            errors.push(`${capitalizeFilter(messagePrefix)} heeft geen interne naam.`);
        }

        if(this.vatGroupId === null) {
            errors.push(`${capitalizeFilter(messagePrefix)} heeft geen BTW groep.`);
        }

        if (type === 'personalization'
            && (
                !this.metadata.hasOwnProperty('designer')
            )
        ) {
            errors.push(`${capitalizeFilter(messagePrefix)} mist een ontwerp (uuid).`);
        }

        if(type === 'card') {
            if(!this.metadata.hasOwnProperty('is_card')
                || !this.metadata.hasOwnProperty('text')
                || (typeof this.metadata.text === 'string' && this.metadata.text.length === 0)) {
                errors.push(`Tekst voor ${messagePrefix} is verplicht.`);
            }
        }

        if(type === 'letter'
            && (
                !this.metadata.hasOwnProperty('letter')
                || !this.metadata.letter.hasOwnProperty('uuid')
                || !this.metadata.letter.hasOwnProperty('filename')
                || !this.metadata.letter.hasOwnProperty('path')
            )
        ) {
            errors.push(`${capitalizeFilter(messagePrefix)} bevat geen geuploade brief.`);
        }
    };

    this.getErrors = function() {
        return errors;
    };

    this.isValid = function() {
        return errors.length === 0;
    };

    this.construct = function() {
        const data = validationData || {};

        for (const property in data) {
            if (data.hasOwnProperty(property)) {
                this.set(property, data[property]);
            }
        }

        this.validate();
    };

    this.construct();
}
