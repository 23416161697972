import EditProcess from './actions/edit_process';
import EditLetter from './actions/edit_letter';
import SendInvoice from './actions/send_invoice';
import CancelOrder from './actions/cancel_order';
import EditOrder from './actions/edit_order';
import FastForward from './actions/fast_forward';
import ReEvaluate from './actions/re_evaluate';
import ReleaseAutoOrderHold from './actions/release_auto_order_hold'
import EditOrderLineCard from './actions/edit_order_line_card'
import GenerateDesign from './actions/generate_design'
import ProcessOrderModal from '../modals/process_order_modal';
import ViewDesign from './../order/view_design';
import {Swal} from '../../vue/utils/swal';
import {cancelOrderCollection} from './actions/cancel_order_collection';
import {fraudcheckActions} from './actions/reset_fraud_order_collection';
import {showNotification} from '../toast';
import { RESTITUTION_TYPE } from "../contstants/restitution";
import { checkVoucherCode } from './actions/check_voucher_code'
import { scrollParentToChild } from '../utils/scroll'
import axios from 'axios'
import SupplierManagerInsightsModal from './supplier_manager_insights';
import OpenWindow from '../utils/open_window'
import { initializeVueComponent } from '../../../plugins/vue'
import OrderLock from "./OrderLock";

/**
 * @param {Element} swalElement
 */
const onOpenCancellationPopup = swalElement => {
    document.querySelector('.swal2-container').style.zIndex = '2001';
    swalElement.querySelector('textarea').value = 'Order geannuleerd op verzoek van klant';
};

export default function Order(url, orderId = null, orderUuid = null) {
    this.contentWindow = new ContentWindow({
        width: window.innerWidth * 0.9 + 'px',
        hideCallback: () => {
            this.orderLock.unlockCurrent()
        },
        zIndex: 2000,
        id: 'order_content_window'
    });

    this.open(url, orderId, orderUuid)
    this.vueInstance = initializeVueComponent()
    this.orderLock = new OrderLock()
}

Order.prototype.open = function (url, orderId, orderUuid) {
    var self = this;

    this.url = url;
    this.orderId = orderId;
    this.orderUuid = orderUuid;
    this.contentWindow.empty().show();
    this.contentWindow.setOption('refreshUrl', this.url);
    this.contentWindow.setOption('refreshCallback', function () {
        self.initialize();
    });

    this.orderXhr = null;

    $.get(url).done(function (data) {
        self.contentWindow.setHtml(data);

        $('body').trigger('order-modal:html:loaded', {
            orderModal: self
        })

        self.bindEvents();
        self.initialize();
    }).fail(function () {

    });
};

Order.prototype.initialize = function () {

    this.initializePopovers();
    this.initializeTooltips();
    this.initializeSwitchery();

    this.showOrder();
    this.checkDrafts();
    this.scrollToOrderId(this.orderId);

    var orderCount = $('#order-orders').find('.panel');
    if (orderCount.length === 1) {
        $('#order-orders .panel .heading-elements').hide();
    }
};

Order.prototype.bindEvents = function () {
    var self = this;
    var elm = self.contentWindow.elm;

    var pdfViewerParameters = {
        toolbarButtons: [
            {
                position: 'right',
                action: 'pdf-print',
                label: 'Afdrukken',
                icon: 'icon-printer',
                order: 5,
                classNames: 'btn-primary',
                disabled: true
            },
            {
                position: 'right',
                action: 'pdf-download',
                label: 'Opslaan',
                icon: 'icon-file-download',
                order: 10,
                classNames: 'btn-primary',
                disabled: true
            }
        ]
    };

    elm.on('click', '#payment_request', function (e) {
        e.preventDefault();

        openContent($(this));
    });

    elm.on('click', '[data-action="open-suppliers-for-delivery"]', function (e) {
        e.preventDefault();
        e.stopPropagation();

        SupplierManagerInsightsModal($(this).data('order-id'))
    });

    elm.on('click', 'i[data-action="show-activity-popup"]', function () {
        var elm = $(this);

        swal({
            title: elm.attr('data-title'),
            type: 'info',
            html: elm.attr('data-content')
        });
    });

    elm.on('click', 'button[data-action="show-activity"]', function () {
        var activityPath = $(this).closest('.view').attr('data-ordercollection-activity-path');
        self.loadActivities(activityPath);
    });

    elm.on('click', 'a[data-action="view-pdf"]', function (e) {
        e.preventDefault();

        new ViewPDF($(this).attr('href'), pdfViewerParameters);
    });


    elm.on('click', '[data-action="open-google-maps"]', function (e) {
        const address = e.currentTarget.dataset.address
        if (!address) {
            return;
        }

        const googleUrl = 'https://www.google.com/maps/place/' + encodeURI(address);
        const features = 'height=847,width=908,left=945,top=105';
        new OpenWindow(googleUrl, 'Google Maps', features);
    });

    elm.on('click', '[data-action="open-postcode-nl"]', function (e) {
        const url = e.currentTarget.dataset.url
        if (!url) {
            return;
        }

        const features = 'height=847,width=908,left=945,top=105';
        new OpenWindow(url, 'Postcode.nl', features);
    });

    elm.on('click', '[data-action="review-fraud"]', function (e) {
        e.preventDefault();

        var fraudFields = JSON.parse($('#fraudFields').attr('data-fields'));

        var checkboxes = $('<div class="checkboxes"></div>');
        $.each(fraudFields, function (key, field) {
            var checkboxHolder = $('<div class="checkbox" style="text-align: left;"></div>');

            var checkbox = $('<input id="fraud-' + key + '" class="fraudField" type="checkbox"/>');
            var label = $('<label for="fraud-' + key + '">' + field.description + ' (score: ' + field.score + ')</label>');

            checkboxHolder.append(checkbox).append(label);
            checkboxes.append(checkboxHolder);
        });

        swal({
            title: 'Mogelijke fraude gedetecteerd',
            text: 'Vink de onderstaande punten af om de order te kunnen verwerken.',
            html: checkboxes.html(),
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Deze order is in orde',
            cancelButtonText: 'Terug naar order',
            focusConfirm: false,
            preConfirm: function () {
                return new Promise(function (resolve) {
                    var checked = true;
                    $('.fraudField').each(function () {
                        if (!$(this).is(':checked')) {
                            checked = false;
                        }
                    });

                    if (checked) {
                        resolve();
                    } else {
                        swal.showValidationError('Alle fraude mogelijkheden moeten zijn afgevinkt voordat de order kan worden doorgestuurd.');
                        $('.swal2-buttonswrapper').find('button').attr('disabled', false);
                    }

                });
            }
        }).then(() => {
            document.querySelector('[data-action="process-order"]').removeAttribute('disabled');
        });
    });

    elm.on('click', 'a[data-action="view-invoice-pdf"]', function (e) {
        e.preventDefault();

        var buttons = JSON.parse(JSON.stringify(pdfViewerParameters.toolbarButtons));

        buttons.push({
            position: 'right',
            action: 'send-invoice',
            label: 'Verstuur per e-mail',
            icon: 'icon-mail5',
            order: 0,
            classNames: 'btn-primary',
            disabled: true,
            href: $('a[data-action=send-invoice]', self.contentWindow.elm).attr('href'),
            dataAttributes: {
                email: $('a[data-action=send-invoice]', self.contentWindow.elm).attr('data-email')
            }
        });

        new ViewPDF($(this).attr('href'), {
            toolbarButtons: buttons
        });
    });

    elm.on('click', 'a[data-action=edit-order]', function (e) {
        e.preventDefault();

        if ($(this).attr('disabled')) {
            return;
        }

        new EditOrder($(this).attr('href'), self.contentWindow, self);
    });

    elm.on('click', '[data-action=fast-forward]', function (e) {
        e.preventDefault();

        if (this.classList.contains('disabled')) {
            return false;
        }

        const href = (this.nodeName === 'BUTTON') ? this.dataset.href : this.href;
        const type = href.includes('order-collection') ? 'order-collection' : 'order';
        const html = '<p>Alle orders in deze bestelling worden zo snel als mogelijk doorgestuurd, voor zover ze automatisch verwerkbaar zijn.</p>';

        function fastForwarded(result) {
            if (result) {
                let fastForwardElements = null
                fastForwardElements = (type === 'order-collection') ?
                    document.querySelectorAll('[data-action=fast-forward]') : [e.target]

                if (fastForwardElements !== null) {
                    fastForwardElements.forEach(el => {
                        el.dataset.popup = 'tooltip'
                        el.dataset.originalTitle = 'Ik ga al!'
                        el.classList.add('disabled')
                        if (el.nodeName === 'BUTTON') {
                            el.disabled = true
                        }
                    })
                }
            }
        }

        if (type === 'order') {
            return FastForward(href).then(result => fastForwarded(result));
        }

        Swal.fire({
            type: 'question',
            title: 'Versneld doorsturen?',
            confirmButtonText: 'Bevestigen',
            cancelButtonText: 'Annuleren',
            html: html,
            allowOutsideClick: true
        }).then(confirm => {
            if (confirm) {
                return FastForward(href).then(result => fastForwarded(result))
            }
        });
    });

    elm.on('click', '[data-action=re-evaluate]', function (e) {
        e.preventDefault();

        if (this.classList.contains('disabled')) {
            return false;
        }

        const href = (this.nodeName === 'BUTTON') ? this.dataset.href : this.href;
        const html = '<p>Alle orders in deze bestelling worden geherevalueerd.</p>';

        Swal.fire({
            type: 'question',
            title: 'Bestelling herevalueren?',
            confirmButtonText: 'Bevestigen',
            cancelButtonText: 'Annuleren',
            html: html,
            allowOutsideClick: true
        }).then(confirm => {
            if (!confirm) {
                return false;
            }

            return ReEvaluate(href).then(result => {
                if (result) {
                    const el = document.querySelector('[data-action=re-evaluate]');
                    el.dataset.popup = 'tooltip';
                    el.dataset.originalTitle = 'Bestelling wordt al geherevalueerd.';
                    el.classList.add('disabled');
                }
                if (window.orderSearch) {
                    window.orderSearch.search();
                }

                self.contentWindow.refresh();
            })
        });
    });

    $(document).on('click', 'button[data-action=release-auto-order-hold]', function (e) {
        e.preventDefault()

        const href = this.dataset.href
        const swalOptions = {
            type: 'question',
            title: 'Order vrijgeven?',
            confirmButtonText: 'Vrijgeven',
            cancelButtonText: 'Annuleren',
            text: 'Om de order vrij te kunnen geven moet het adres aangepast en correct zijn...',
            allowOutsideClick: true
        }

        Swal.fire(swalOptions).then(confirm => {
            if (!confirm) {
                return false
            }

            return ReleaseAutoOrderHold(href).then(result => {
                if (result) {
                    window.location.reload()
                }
            })
        });
    });

    elm.on('click', 'a[data-action=process-order]', function (e) {
        e.preventDefault();

        if ($(this).attr('disabled')) {
            Swal.fire({
                title:'Verwerken niet mogelijk',
                showCancelButton: false
            })
            return;
        }

        new ProcessOrderModal($(this).attr('href'), self);
    });

    elm.on('click', 'a[data-action=cancel-order]', function (e) {
        e.preventDefault();

        new CancelOrder($(this).attr('href'));
    });

    elm.on('click', 'a[data-event="edit-process"]', function (e) {
        e.preventDefault();

        new EditProcess($(this).data('href'));
    });

    elm.on('click', 'a[data-event="edit-invoice-reference"]', function (e) {
        e.preventDefault();

        const url = $(this).data('href');
        $.get(url).then(function (result) {
            swal({
                type: 'question',
                title: 'Factuurreferentie',
                input: 'text',
                inputValue: result.invoiceReference,
                showCancelButton: true,

                onOpen: function () {
                    $('.swal2-container').css({
                        'z-index': 2011
                    })
                },

                inputValidator: function (input) {
                    return new Promise(function (resolve, reject) {
                        if (input) {
                            resolve()
                        } else {
                            reject('Veld mag niet leeg zijn!');
                        }
                    })
                }
            }).then(function (input) {
                $.post(url, {
                    invoiceReference: input,
                }).then(function (result) {
                    swal(result.message);
                }, function (error) {
                    swal(error.message);
                });
            });
        });
    });

    elm.on('click', 'a[data-event="assume-submitted"]', function (e) {
        e.preventDefault();

        const url = $(this).data('href');

        swal({
            type: 'warning',
            title: 'Markeer als doorgestuurd?',
            text: 'Weet je zeker dat je deze order als "Doorgestuurd" wilt markeren?',
            showCancelButton: true,

            onOpen: function () {
                $('.swal2-container').css({
                    'z-index': 2011
                })
            }
        }).then(function (result) {
            if(result) {
                $.post(url).then(function (result) {
                    swal(result.message, null, 'success');
                }).fail(function(result){

                    if(typeof(result.responseJSON) === "undefined") {
                        swal('Onbekende fout', null, 'error');
                        return;
                    }

                    const errors = result.responseJSON.errors || [];
                    swal(result.responseJSON.message, errors.join('<br>'), 'error');
                });
            }
        });
    });

    const btnApprovePaymentElm = document.getElementById('btn-approve-payment')
    if(btnApprovePaymentElm !== null) {
        btnApprovePaymentElm.addEventListener('click', (e) => {
            e.preventDefault();

            const url = e.target.dataset.href;

            swal({
                type: 'warning',
                title: 'Betaling goedkeuren?',
                text: 'Weet je zeker dat je de betaling voor deze order wil goedkeuren?',
                showCancelButton: true,

                onOpen: function () {
                    $('.swal2-container').css({
                        'z-index': 2011
                    })
                }
            }).then(function (result) {
                if(result) {
                    axios.post(url)
                    .then(function ({data}) {
                        swal(data.message, null, 'success');
                        self.contentWindow.refresh();
                    })
                    .catch(function(error){

                        if(typeof(error.response.data.errors) === "undefined") {
                            swal('Onbekende fout', null, 'error');
                            return;
                        }

                        const errors = error.response.data.errors || [];
                        swal(error.response.data.message, errors.join('<br>'), 'error');
                    });
                }
            });
        })
    }

    elm.on('click', '.order-line-card-edit, .order-line-inputfields-edit', function (e) {
        e.preventDefault();
        var btnElm = $(this);
        new EditOrderLineCard(btnElm.attr('href'), {
            parent: self.contentWindow,
            refreshUrl: self.url
        });
    });

    elm.on('click', '.order-line-design-edit', function (e) {
        e.preventDefault();
        var btnElm = $(this);
        new ViewDesign(btnElm.attr('href'), {
            parent: self.contentWindow,
            refreshUrl: self.url
        });
    });

    elm.on('click', '.order-line-design-generate', function (e) {
        e.preventDefault();
        var btnElm = $(this);

        new GenerateDesign(btnElm.attr('href'), {
            parent: self.contentWindow,
        });
    });

    elm.on('click', '.order-line-letter-edit', function (e) {
        e.preventDefault();
        var btnElm = $(this);

        new EditLetter(btnElm.attr('href'), {
            parent: self.contentWindow,
            refreshUrl: self.url
        });
    });

    $('body').on('click', 'a[data-action=send-invoice]', function (e) {
        e.preventDefault();

        new SendInvoice({
            url: $(this).attr('href'),
            email: $(this).attr('data-email')
        });
    });

    elm.on('click', '.order-order.panel .panel-heading', function (e) {
        e.preventDefault();

        const uuid = $(this).closest('.order-order').attr('data-order-uuid');

        self.showOrder({ uuid });
    });

    $('i.glyphicon').on('mouseover', function (e) {
        e.stopPropagation();

        $(this).tooltip('show');
        $(this).parent().parent().popover('hide');
    });

    $('[data-popup="tooltip"]').tooltip();

    elm.on('click', 'a[data-action=reset-ajax-order-fraud-all]', (e) => {
        e.preventDefault();

        Swal.fire({
            type: 'question',
            confirmButtonText: 'Bevestigen',
            title: 'Bevestigen',
            html: '<div>Fraude op orders resetten?<div>',
            allowOutsideClick: true,
            preConfirm: () => {
                return fraudcheckActions({url: e.target.getAttribute('href')}).then(() => {
                    self.contentWindow.refresh();
                });
            }
        });
    });

    elm.on('click', 'a[data-action=recheck-ajax-order-fraud]', (e) => {
        e.preventDefault();

        Swal.fire({
            type: 'question',
            confirmButtonText: 'Bevestigen',
            title: 'Bevestigen',
            html: '<div>Fraudecheck nogmaals uitvoeren?<div>',
            allowOutsideClick: true,
            preConfirm: () => {
                return fraudcheckActions({url: e.target.getAttribute('href')}).then(() => {
                    self.contentWindow.refresh();
                });
            }
        });
    });

    /**
     * @documentation On click event that triggers the current order collection
     * @param {event} e
     */
    elm.on('click', 'a[data-action=cancel-ajax-order-all]', (e) => {
        e.preventDefault();

        const swalInput = 'textarea';

        Swal.fire({
            type: 'question',
            confirmButtonText: 'Bevestigen',
            title: 'Bevestigen',
            html: '<div>Weet je zeker dat je alle orders wilt annuleren?<br><br></div><small>Reden:</small>',
            input: swalInput,
            onOpen: onOpenCancellationPopup,
            preConfirm: (reason) => {
                return new Promise(function (resolve, reject) {
                    if (reason) {
                        cancelOrderCollection({
                            reason,
                            type: 'consumer',
                            url: e.target.getAttribute('href'),
                        }).then(() => {
                            self.contentWindow.refresh();
                        });

                        return resolve();
                    }

                    reject('Vul s.v.p. eerst een geldige reden in.');
                });
            }
        });
    });

    elm.on('click', 'button[data-action=cancel-ajax-order]', function (e) {
        e.preventDefault();

        var btn = $(this);
        var url = btn.data('url');
        var type = btn.data('type');
        var number = btn.data('order-number');

        var message = '<div>Weet je zeker dat je order ' + number + '<br> wilt annuleren?<br><br></div><small>Reden:</small>';

        if (type === 'supplier') {
            message = '<div>Weet je zeker dat je deze order wilt weghalen bij de leverancier?<br><br></div><small>Reden:</small>';
        }

        self.swal(message, url, type, number)
    });

    elm.on('click', 'a[data-action=payment-invoice-cancellation]', function (e) {
        e.preventDefault();

        var btn = $(this);
        var cancellationUrl = btn.data('cancellation');
        var url = btn.data('url');

        var message = 'Dit verzoek annuleert de factuurbetalingsmethode en opent een nieuw betalingsverzoek.';

        swal({
            type: 'question',
            title: 'Factuurbetaling annuleren?',
            showCancelButton: true,
            confirmButtonText: 'Factuurbetaling annuleren',
            confirmButtonColor: '#ef5350',
            reverseButtons: true,
            text: message
        }).then(function () {
            $.ajax({
                url: cancellationUrl,
                method: 'GET'
            }).done(function () {
                self.contentWindow.refresh();
                swal({
                    type: 'success',
                    title: 'Gelukt',
                    text: 'De factuurbetaling is succesvol geannuleerd',
                    allowOutsideClick: false
                }).then(function () {
                    openContent($('#payment_request'));
                })
            }).fail(function () {
                swal({
                    type: 'error',
                    title: 'Mislukt',
                    text: 'Factuurbetaling kan niet geannuleerd worden',
                    allowOutsideClick: true
                });
            });
        }).catch(swal.noop);
    });

    elm.on('click', 'button[data-action="restitute-ajax-order"]', function (e) {
        e.preventDefault();

        const href = $(this).attr('data-url');
        const self = this;

        const contentWindow = new ContentWindow({
            id: 'restitution-window',
            width: window.innerWidth * 0.35 + 'px',
            zIndex: 2001
        });
        contentWindow.empty().show();
        contentWindow.setOption('refreshUrl', href);
        contentWindow.setOption('beforeSubmit', function () {
            const checkedRestitutionOption = document.getElementById('restitution_form').querySelector(
                'input[name=restitution-option]:checked'
            );

            if (null === checkedRestitutionOption) {
                showNotification({text: 'Kies eerst het type vergoeding', type: 'error'});

                return false;
            }

            if (RESTITUTION_TYPE.AMOUNT === checkedRestitutionOption.value) {
                const value = parseFloat(document.getElementById('restitution_amount').value.replace(',', '.'));

                if (true === isNaN(value) || value <= 0) {
                    showNotification({text: 'Voer een geldig positief bedrag in', type: 'error'});

                    return false;
                }
            }

            return true;
        });

        contentWindow.setOption('afterSubmit', () => {
            showNotification({text: 'Order vergoed'});
        });

        self.orderXhr = null;

        $.get(href).done(function (data) {
            contentWindow.setHtml(data);
        });
    });

    elm.on('click', '.order-collection-line.panel .panel-heading', function (e) {
        e.preventDefault();
        $('.order-order').each(function () {
            $(this).find('.panel-body').remove();
            $(this).find('.panel-footer').remove();
            $('a[data-action="collapse"]').show();
        });
        $('.order-collection-line.hidden-content').show();
    });

    elm.on('click', 'a[data-action="apply-voucher"]', function (e) {
        e.preventDefault()

        swal({
            type: 'info',
            title: 'Korting toepassen',
            text: 'Voer de kortingscode in',
            input: 'text',
            showCancelButton: true,
            inputValidator: function (value) {
                return new Promise(function (resolve, reject) {
                    if (value) {
                        resolve()
                    } else {
                        reject('Er moet een voucher code ingevoerd worden.')
                    }
                })
            },
        }).then( code => {
            return checkVoucherCode({
                code: code,
                url: e.target.getAttribute('href'),
            }).then( result => {
                if (result === true) {
                    self.contentWindow.refresh()
                }
            })
        })
    })
};

/**
 * @param {string} message
 * @param {string} url
 * @param {string} type
 * @param {number} number
 */
Order.prototype.swal = function (message, url, type, number) {
    var self = this;
    swal({
        type: 'question',
        title: 'Bevestiging',
        html: message,
        input: 'textarea',
        showCancelButton: true,
        confirmButtonText: 'Bevestigen',
        onOpen: onOpenCancellationPopup,
        inputValidator: function (text) {
            return new Promise(function (resolve, reject) {
                if (text) {
                    resolve()
                } else {
                    reject('Reden veld mag niet leeg zijn!');
                }
            })
        }
    }).then(function (text) {
        $.ajax({
            url: url,
            method: 'POST',
            data: {reason: text, type: type}
        }).done(function () {
            swal({
                type: 'success',
                title: 'Gelukt',
                text: 'Order ' + number + ' is succesvol geannuleerd',
                allowOutsideClick: false
            }).then(function () {
                self.contentWindow.refresh();

                if (window.orderSearch) {
                    window.orderSearch.search();
                }

                var orderSiteSelectionOnChangeTriggerElement = $('#orderSiteSelection');
                if (orderSiteSelectionOnChangeTriggerElement) {
                    orderSiteSelectionOnChangeTriggerElement.trigger('change');
                }
            }, function () {
                window.location.reload();
            });
        }).fail(function () {
            swal({
                type: 'error',
                title: 'Mislukt',
                text: 'Order ' + number + ' kan niet geannuleerd worden',
                allowOutsideClick: true
            });
        });
    }, function (dismiss) {
    });

    $('button[data-action="show-activity"], a[data-action="show-activity"]').unbind().on('click', function () {
        var value = $(this).data('value');

        $('.panel[data-order-id="' + value + '"]').find('a[data-action="collapse"]').trigger('click');

        self.showOrderActivity(value);
    });
};

Order.prototype.checkDrafts = function () {
    var self = this;

    $('#order_finalize_drafts').remove();

    $.ajax({
        url: self.url,
        data: {
            check_drafts: true
        }
    }).done(function (response) {
        var saveButtonHtml = '<a id="order_finalize_drafts" data-action="order-finalize-drafts" class="btn btn-xs btn bg-green order-save">Maak gewijzigde order(s) definitief</a>';

        if (
            response.hasOwnProperty('hasDrafts')
            && response.hasOwnProperty('finalizeUrl')
            && response.hasDrafts === true
        ) {
            var existingBtn = $('#order_finalize_drafts');
            if (existingBtn.length > 0) {
                existingBtn.remove();
            }

            $(saveButtonHtml).appendTo($('.pull-right', '.content-window-toolbar'));
            self.bindFinalizeEvent(response.finalizeUrl);
        }
    });
};

Order.prototype.bindFinalizeEvent = function (url) {
    var self = this;

    $('#order_finalize_drafts').on('click', function (e) {
        e.preventDefault();

        swal({
            title: 'Opletten',
            type: 'warning',
            html: `<p>Let op: Na het definitief maken van de orders ontvangt de klant een bevestigings bericht met hierin (eventueel) een betaallink.</p>
                    <p>Reden: <small><i>(Wordt ook met de email meegezonden naar de klant...)</i></small></p>`,
            confirmButtonText: 'Maak gewijzigde order(s) definitief',
            confirmButtonColor: '#EF5350',
            showCancelButton: true,
            cancelButtonText: 'Annuleren',
            showLoaderOnConfirm: true,
            input: 'textarea',
            inputAutoTrim: true,
            inputValidator: function (finalize_message) {
                return new Promise(function (resolve, reject) {
                    if (finalize_message) {
                        resolve()
                    } else {
                        reject('Reden veld mag niet leeg zijn!');
                    }
                })
            },
            preConfirm: function (finalize_message) {
                return new Promise(function (resolve) {
                    $.ajax({
                        url: url,
                        method: 'post',
                        data: {
                            finalize_message: finalize_message
                        }
                    }).done(function (response) {
                        self.contentWindow.refresh();

                        var html = '<p>De order is met success definitief gemaakt</p>',
                            textInvoice = '<p>- Op rekekening!: Bevestigingsemail <strong>zonder</strong> betalingsverzoek verstuurd.</p>',
                            textEmail = '<p>- Bevestigingsemail is verstuurd.</p>',
                            textPaymentRequestSent = '<p>- Een betalingsverzoek is verstuurd.</p>',

                            hasInvoice = response.hasOwnProperty('invoice') && response.invoice === true,
                            emailSent = response.hasOwnProperty('emailSent') && response.emailSent === true,
                            paymentRequestSent = response.hasOwnProperty('paymentRequestSent')
                                && response.paymentRequestSent === true;

                        html = (hasInvoice) ? html + textInvoice : html;
                        html = (emailSent) ? html + textEmail : html;
                        html = (paymentRequestSent) ? html + textPaymentRequestSent : html;

                        swal({
                            type: 'success',
                            title: 'Gelukt',
                            html: html
                        });
                    }).fail(function () {
                        swal({
                            type: 'error',
                            title: 'Helaas is er iets misgegaan...',
                            text: 'De gewijzigde orders zijn niet (helemaal) definitief gemaakt.'
                        });
                    });
                    resolve();
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .catch(swal.noop);
    });
};

Order.prototype.initializePopovers = function () {
    $('[data-toggle="popover"]', this.contentWindow.elm).popover()
};

Order.prototype.initializeTooltips = function () {
    $(this.contentWindow.elm).tooltip({
        selector: '[data-popup=tooltip]'
    });
};

Order.prototype.initializeSwitchery = function () {
    var input = $('input.switchery', this.contentWindow.elm);

    input.on('change', function () {
        if ($(this).is(':checked')) {
            $('.excl-vat').hide();
            $('.incl-vat').show();
        } else {
            $('.excl-vat').show();
            $('.incl-vat').hide();
        }
    }).trigger('change');

    new Switchery(input.get(0));
};

Order.prototype.triggerInclExclSwitch = function () {
    if ($(this.contentWindow).length > 0) {
        $('input.switchery', this.contentWindow.elm).trigger('change');
    }
};

Order.prototype.showOrder = function (props = { uuid: null }) {
    let orderUuid = props.uuid || null

    if (orderUuid === null) {
        if (this.orderId !== null) {
            orderUuid = $('#order-orders').find('.order-order[data-order-id="' + this.orderId + '"]').data('order-uuid');
        }

        if (this.orderUuid !== null) {
            orderUuid = this.orderUuid
        }
    }

    this.orderLock.init({ uuid: orderUuid })

    const self = this;
    const orderPanelElm = $('.panel[data-order-uuid="'+ orderUuid +'"]');
    const orderId = orderPanelElm.data('order-id');
    const toggleBtn = $('[data-action="collapse"]', orderPanelElm);
    const allToggleBtn = $('[data-action="collapse"]');

    orderPanelElm.find('.panel-body').remove();
    orderPanelElm.find('.panel-footer').remove();
    orderPanelElm.append('<div class="panel-body">Laden...</div>')

    allToggleBtn
      .find('i')
      .removeClass('mdi-chevron-up')
      .addClass('mdi-chevron-down')

    allToggleBtn.show();
    $('.panel-collapsed').children('.panel-heading').nextAll().hide();

    if (orderId) {
        if (this.orderXhr && typeof this.orderXhr.abort === 'function') {
            this.orderXhr.abort();
        }

        const url = $('#order-orders').attr('data-order-order-url');

        this.orderXhr = $.ajax({
            url,
            data: {
                order: orderId
            },
            responseType: 'html',
            method: 'POST',
        }).then(function (html = '') {
            self.orderXhr = null;
            $('.order-order .panel-body, .order-order .panel-footer').remove();
            orderPanelElm.append(html);

            toggleBtn
              .find('i')
              .addClass('mdi-chevron-up')
              .removeClass('mdi-chevron-down')

            self.showOrderActivity(orderId);
            self.triggerInclExclSwitch();
        });
    }
};

Order.prototype.showOrderActivity = function (orderId) {
    var activityPath = $('.order-order[data-order-id="' + orderId + '"]').find('.panel-body').attr('data-activity-url');
    this.loadActivities(activityPath);
};

Order.prototype.loadActivities = function (url) {
    const orderActivityElm = $('.order-activity');
    const outputElem = orderActivityElm.find('table')

    if (typeof url === 'undefined') {
        outputElem.html('Geen resultaten gevonden...\n');
        return;
    }

    $.ajax({
        url: url,
        method: 'GET',
    }).then(function (response) {
        outputElem.html(response);
        $('body [data-toggle="popover"]').popover()
    });
};

Order.prototype.scrollToOrderId = function (orderId) {
    const childSelector = `[data-order-id="${orderId}"]`
    const child = document.querySelector(childSelector)
    const parent = document.querySelector('#order-orders')
    if (child === null || parent === null) {
        return;
    }

    scrollParentToChild(parent, child)
};

window.Order = Order; // add to global scope

$(function () {
    $('body').on('click', '[data-action="view-order"]', function (e) {
        e.preventDefault();

        new Order($(this).attr('data-href'));
    });
});
