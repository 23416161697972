export default function EditOrderLineCard(url, parameters) {
    if (typeof (ContentWindow) === 'undefined') {
        throw 'Missing ContentWindow dependency';
    }

    var options = {
        id: 'content_window_card_edit',
        width: '500px',
        zIndex: 3000,
        toolbarButtons: [
            {
                id: 'card_submit',
                position: 'right',
                label: 'Opslaan',
                order: 1,
                classNames: 'btn btn-primary',
                action: 'card-save'
            }
        ]
    };

    options = $.extend(options, parameters);

    this.contentWindow = new ContentWindow(options);

    // Workaround the set correct parent...
    this.contentWindow.options.parent = parameters.parent;

    this.open(url, options);
}

EditOrderLineCard.prototype.open = function (url, options) {
    var self = this;

    this.url = url;

    $.get(url).done(function (data) {
        self.contentWindow.setOption('toolbarButtons', options.toolbarButtons);
        self.contentWindow.empty().show();
        self.contentWindow.setHtml(data);

        $(self.contentWindow.elm).on('click', '#card_submit', function () {
            var elm = $(this);
            var contentWindow = elm.closest('.content-window');
            var form = contentWindow.find('form');

            form.submit();
        });
    });
};
