export default function OpenWindow(url, target, features) {
  if (!url) {
    return;
  }

  if (!features) {
    features = 'height=847,width=908,left=945,top=105';
  }

  window.open(
    url,
    target,
    features
  );
}
