const elements = []

export const copyToClipboard = (selector = '.copy-to-clipboard') => {
  const copyToClipboardElement = document.querySelector(selector)

  if (copyToClipboardElement !== null && !elements.includes(copyToClipboardElement)) {
    elements.push(copyToClipboardElement)

    const copyNode = document.createElement('i')
    copyNode.className = 'mdi mdi-content-copy copy-reveal'
    copyNode.addEventListener('click', event => { copyToClipboardElement.click() })

    copyToClipboardElement.parentNode.insertBefore(copyNode, copyToClipboardElement)

    copyToClipboardElement.addEventListener('click', event => {
      event.preventDefault()

      const inputElement = document.createElement('input')
      inputElement.value = copyToClipboardElement.innerHTML.trim()
      document.body.appendChild(inputElement)
      inputElement.select()

      if (document.execCommand('copy')) {
        window.showNotification({
          type: 'info',
          text: 'Gekopieerd naar klembord'
        })
      }
    })
  }
}

window.copyToClipboard = copyToClipboard

const callback = function (mutationsList, observer) {
  for (const mutation of mutationsList) {
    if (mutation.type === 'childList') {
      const copyToClipboardNode = mutation.target.querySelector('.copy-to-clipboard')
      if (copyToClipboardNode) {
        copyToClipboard()
      }
    }
  }
}

const observer = new MutationObserver(callback)
observer.observe(document.body, { childList: true, subtree: true })
