export default function GenerateDesign (url, parameters = {}) {
  if (typeof (ContentWindow) === 'undefined') {
    throw 'Missing ContentWindow dependency';
  }

  var options = {
    id: 'content-window-letter',
    width: '1200px',
    zIndex: 3000,
    toolbarButtons: []
  }
  options = $.extend(options, parameters);

  this.contentWindow = new ContentWindow(options)

  this.open(url, options)
}

GenerateDesign.prototype.open = function (url, options) {
  var self = this

  self.contentWindow.setOption('toolbarButtons', options.toolbarButtons)
  self.contentWindow.empty().show()

  $.get(url).done(function (data) {
    self.contentWindow.setHtml(data)
  })
}
