import * as Sentry from "@sentry/browser";

const doConsoleLogSentryConfig = new URL(location.href).searchParams.get('debug-sentry') !== null;
const tracesSampleRate = window.SENTRY_TRACES_SAMPLE_RATE;
const replaysSessionSampleRate = window.SENTRY_REPLAYS_SESSION_SAMPLE_RATE;
const replaysOnErrorSampleRate = window.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE;

const sentryConfig = {
  dsn: window.SENTRY_DSN,
  environment: window.APP_ENV,
  release: "topgeschenken-admin@" + (window.APP_VERSION),
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],
  tracesSampleRate,
  replaysSessionSampleRate,
  replaysOnErrorSampleRate,
};

if (doConsoleLogSentryConfig) {
  console.info(sentryConfig)
}

Sentry.init(sentryConfig);
