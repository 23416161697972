export default function ViewDesign(url, options) {
    if (typeof (ContentWindow) === 'undefined') {
        throw 'Missing ContentWindow dependency';
    }

    this.id = 'modal_modify_designer';

    options = $.extend({
        id: this.id,
        width: '1200px',
        zIndex: 3000,
        toolbarButtons: [
            {
                id: 'designer-submit',
                position: 'right',
                label: 'Ontwerp opslaan',
                order: 1,
                disabled: true,
                classNames: 'btn btn-primary',
                action: 'design-save'
            }
        ]
    }, options);

    this.contentWindow = new ContentWindow(options);

    this.open(url, options);
}

ViewDesign.prototype.open = function (url, options) {
    var self = this;

    this.url = url;
    this.contentWindow.setOption('toolbarButtons', options.toolbarButtons);
    this.contentWindow.empty().show();
    this.contentWindow.setHtml('<iframe id="modify_design" frameborder="0" style="width: 100%; height: calc(100% - 10px); min-height: 700px;" src="' + url + '"></iframe>');

    self.bindEvents();
};

ViewDesign.prototype.bindEvents = function () {
    $(this.contentWindow.elm).on('click', '[data-action="design-save"]:not([disabled])', this.save);
};

ViewDesign.prototype.save = function (e) {
    e.preventDefault();

    $('#modify_design').contents().find('#action-bar button.btn-add-to-cart').trigger('click');
};

$(function () {
    var bodyElm = $('body');
    var xhr = null;

    if(typeof window.modals === 'undefined') {
        window.modals = [];
    }

    bodyElm.on('click', '.modify-predesign', function (e) {
        e.preventDefault();

        var elm = $(this);
        var form = elm.parents('form');
        var tile = elm.parents('.predesign.tile');
        var actionUrl = tile.attr('data-update-url');
        var designUrl = tile.attr('data-design-url');
        var errorMsg = $('#error_msg');
        var isValid = form.isValid();

        if(errorMsg.length > 0) {
            errorMsg.remove();
        }

        if (typeof(setupFormValidation) !== 'undefined') {
            setupFormValidation({}, true);
        }

        // Check if all required fields are valid.
        if (!isValid) {
            return false;
        }

        if(xhr) {
            xhr.abort();
        }

        xhr = $.ajax({
            url: actionUrl,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
        })
        .done(function (data) {
            // Update form action (to block duplicated create).
            if (data.hasOwnProperty('form_action')) {
                form.attr('action', data.form_action);
            }

            // Update save and design method
            if (data.hasOwnProperty('design_action')) {
                tile.attr('data-update-url', data.design_action);
            }

            // Start designer...
            var modal = new ViewDesign(designUrl);
            window.modals[modal.id] = modal;

            elm.closest('.tile').attr('data-modal-id', modal.id);
        })
        .fail(function() {
            elm.closest('.tile').after('<div id="error_msg"><span>Kan het ontwerp niet bewerken</span></div>');
        })
        .always(function() {
            xhr = null;
        });
    });
});
