import { showNotification } from "./toast";
import { post } from 'axios'

// @todo: import
const $ = window.$;
const CmsFormElements = window.CmsFormElements;
const plupload = window.plupload;
const moxie = window.moxie;
const bootbox = window.bootbox;

export default function Cms() {
    this.init();
}

Cms.prototype.init = function () {
    this.initNotifications();
    this.initElements();
    this.initModals();
    this.bindEvents();
};

Cms.prototype.initNotifications = function () {
    this.notificationStack = {'dir1': 'down', 'dir2': 'left'};

    $('body').on('click', '.ui-pnotify a', function (e) {
        e.preventDefault();

        var notification = $(this).closest('.ui-pnotify');
        var method = $(this).data('method') ? $(this).data('method') : 'get';

        notification.find('a').attr('disabled', 'disabled');

        $.ajax({
            url: $(this).attr('href'),
            method: method
        }).done(function () {
            notification.find('.ui-pnotify-closer').trigger('click');
        }).fail(function () {
            notification.removeClass('bg-success').addClass('bg-danger');
        }).always(function () {
            notification.find('a').remove();
        });

        return false;
    });

    this.renderNotifications();
};

Cms.prototype.initElements = function () {
    this.elements = new CmsFormElements();

    this.initMediaElements();
};

Cms.prototype.initModals = function () {
};

Cms.prototype.initMediaElements = function () {
    plupload.addFileFilter('min_dimensions', function (dimensions, file, cb) {
        var self = this, img = new moxie.image.Image();

        function isInt(n) {
            return Number(n) === n && n % 1 === 0;
        }

        function finalize(result) {
            // cleanup
            img.destroy();
            img = null;

            // if rule has been violated in one way or another, trigger an error
            if (!result) {
                self.trigger('Error', {
                    code: plupload.IMAGE_DIMENSIONS_ERROR,
                    message: 'Afbeelding formaat voldoet niet aan de minimum eisen: ' + dimensions.join(' x '),
                    file: file
                });
            }

            cb(true);
        }

        img.onload = function () {
            // Check if we only need to check the width
            if (dimensions[0] && isInt(dimensions[0]) && (!dimensions[1] || !isInt(dimensions[1]))) {
                finalize(img.width >= dimensions[0]);
                return;
            }

            // Check if we only need to check the height
            if (dimensions[1] && isInt(dimensions[1]) && (!dimensions[0] || !isInt(dimensions[0]))) {
                finalize(img.height >= dimensions[1]);
                return;
            }

            // Check if we need to check the width and height
            if ((dimensions[0] && isInt(dimensions[0])) && (dimensions[1] && isInt(dimensions[1]))) {
                finalize(img.width >= dimensions[0] && img.height >= dimensions[1]);
                return;
            }

            cb(true);
        };

        img.onerror = function () {
            finalize(false);
        };

        img.load(file.getSource());
    });

    $('.media-widget').Media({});
};

Cms.prototype.getElements = function () {
    return this.elements;
};

Cms.prototype.renderNotifications = function () {
    $.each($('notification'), function (i, element) {
        showNotification({
            type: $(element).attr('data-type'),
            class: $(element).attr('data-type'),
            text: $(element).html()
        });
    });
};

Cms.prototype.bindEvents = function () {
    const darkmodeTogglerElem = document.getElementById('darkmode-toggle');
    const darkmodeStyleElem = document.getElementById('darkmode-theme');

    if (darkmodeTogglerElem !== null && darkmodeStyleElem !== null) {
        darkmodeTogglerElem.addEventListener('click', (e) => {
            e.preventDefault();

            const currentMode = darkmodeStyleElem.href !== '' ? 'dark' : 'light';
            if (currentMode === 'dark') {
                darkmodeStyleElem.removeAttribute('href');
            } else {
                darkmodeStyleElem.href = darkmodeStyleElem.dataset.href;
            }
            darkmodeTogglerElem.classList.toggle('darkmode');
            darkmodeTogglerElem.classList.toggle('lightmode');

            const formData = new FormData();
            formData.append('newMode', currentMode === 'dark' ? 'light' : 'dark');
            post('/admin/instellingen/authenticatie/gebruikers/toggle-darkmode', formData);
        });
    }

    //----------------------------------

    if (window.location.hash !== '') {
        var hash = window.location.hash;
        var tab = hash;
        var sub_tab;

        if (hash.indexOf('[') !== -1 && hash.indexOf(']') !== -1) {
            tab = hash.substr(0, hash.indexOf('['));
            sub_tab = hash.substr(hash.indexOf('[') + 1, (hash.indexOf(']') - hash.indexOf('[')) - 1);
        }

        var tabElement = $('a[href="' + tab + '"]');

        if (!tabElement) {
            history.pushState(null, null, document.location.pathname);
        } else {
            tabElement.tab('show');

            if (sub_tab) {
                $('a[href="' + sub_tab + '"]').tab('show');
            }
        }
    }

    // add a hash to the URL when the user clicks on a tab
    $('.content-wrapper .nav-tabs-vertical:first > ul > li > a[data-toggle="tab"]').on('click', function () {
        history.pushState(null, null, $(this).attr('href'));
    });

    // navigate to a tab when the history changes
    window.addEventListener('popstate', function () {
        if (!location.hash || location.hash === '') {
            $('.content-wrapper .nav-tabs-vertical:first > ul > li > a:first').tab('show');
        }

        var activeTab = $('[href="' + location.hash + '"]');

        if (activeTab.length) {
            activeTab.tab('show');
        } else {
            $('.content-wrapper .nav-tabs-vertical:first > u > li > a:first').tab('show');
        }
    });

    $('#entity_log_all').on('click', function () {
        $(document).trigger('click');

        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: window.location.href.replace('bewerken', 'logboek'),
            success: function (data) {
                var modal = bootbox.dialog({
                    animate: false,
                    message: data,
                    title: 'Alle gebeurtenissen',
                    show: true,
                    className: 'modal-log'
                }).find('.modal-dialog').addClass('modal-full');

                $(modal).find('.table').DataTable({
                    autoWidth: false,
                    ordering: false, // @todo: find out how numeric or date sorting works with DataTables, for now disable sorting all together
                    displayLength: 15,
                    dom: '<"datatable-header"f><"datatable-scroll"t><"datatable-footer"ip>',
                    drawCallback: function () {
                        // Enable Select2 select for the length option
                        $('.dataTables_length select').select2({
                            minimumResultsForSearch: '-1'
                        });
                    }
                });

                $(modal).modal('show');
            }
        });
    });

    var secondNavBar = $('#navbar-second');

    // Init
    if (secondNavBar.length === 1) {

        // Affix sidebar
        // ------------------------------

        // When affixed
        secondNavBar.on('affixed.bs.affix', function () {
            $(this).next().css('margin-top', $(this).outerHeight());
            $('body').addClass('navbar-affixed-top');
        });

        // When on top
        secondNavBar.on('affixed-top.bs.affix', function () {
            $(this).next().css('margin-top', '');
            $('body').removeClass('navbar-affixed-top');
        });

        secondNavBar.affix({
            offset: {
                top: secondNavBar.offset().top
            }
        });
    }

	/* Scroll active menu item to top (or highest postion possible) */
	const activeMenuItem = $('ul.navigation-main > li.active');

	if (activeMenuItem.length === 1) {
		const menuContainer = $('.sidebar-fixed .sidebar-content');
        const menuOffset = parseInt($('.sidebar-fixed .category-content').css('height'));

        menuContainer.animate({
            scrollTop: activeMenuItem.offset().top - menuOffset
        }, 0);
	}
};

$(document).ready(function () {
    window.cms = new Cms();
});
