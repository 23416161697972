import { post } from 'axios';
import { showNotification } from '../../toast';
import { Swal } from '../../../vue/utils/swal';

export default function FastForward(url) {
    return post(url)
        .then(({ data }) => {
            showNotification({ text: data.message });
            return true;
        })
        .catch( error => {
            let html = '';
            const errors = error.response.data.message;
            (typeof errors === 'string') ? html += errors : errors.forEach(error => html += `<p>${error}</p>`);

            Swal.fire({
                title: 'Versneld doorsturen Fout',
                html: html,
                type: 'error',
                showCancelButton: false
            })
        });
}
