export default function CancelOrder(url) {
    this.contentWindow = new ContentWindow({
        width: window.innerWidth / 2 + 'px',
        zIndex: 2020
    });

    this.open(url);
}

CancelOrder.prototype.open = function (url) {
    this.url = url;
    this.contentWindow.empty().show();

    $.get(url).done((data) => {
        this.contentWindow.setHtml(data);
    });
};
