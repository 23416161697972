import { showNotification } from './toast';


/**
 * @param {number} code
 *
 * @return {string}
 */
export function getErrorMessageForCode(code) {
    switch(code) {
        // 400 - Bad Request. Generic error, should be used as little as possible, intented for cases not specified below.
        case 400:
            return 'Opslaan niet gelukt';
        // 403 - Forbidden. User is not granted this action (eg: denyUnlessGranted, denyUnlessEnabled2FA).
        case 403:
            return 'Het is niet toegestaan deze actie uit te voeren';
        // 409 - Conflict. Conflict with the current state of the resource (eg: entity is a duplicate, or entity has been changed already).
        case 409:
            return 'Dit item bestaat al';
        // 422 - Unprocessable Entity. Sent data could not be properly validated (eg: $validatorService->validate($entity) failing).
        case 422:
            return 'Niet alles is goed ingevuld';
        // Catch all remaining errors in a generic message.
        default:
            return 'Er is onverwacht iets fout gegaan';
    }
}

/**
 * @param {object} error
 */
export function standardXHRErrorHandler(error) {
    if (error.response) {
        let message = null;
        if(error.response.data && error.response.data.message) {
            message = error.response.data.message
        }

        showNotification({text: message || getErrorMessageForCode(error.response.status), type: 'error'});
    }
}

window.getErrorMessageForCode = getErrorMessageForCode;
