import SelectAlternativeSupplierModal from '../modals/select_alternative_supplier_modal';
import { showNotification } from "../toast";
import OrderLock from "../order/OrderLock";

export default function ProcessOrderModal(url, parentModal) {
    this.xhr = null;
    this.url = null;
    this.parentModal = null;
    this.contentWindow = null;
    this.supplier = {
        id: null,
        name: null
    };
    this.orderId = null;
    this.orderUuid = null;
    this.orderNumber = null;

    this.init(url, parentModal);
    this.orderLock = new OrderLock()
}

ProcessOrderModal.prototype.init = function(url, parentModal = null) {
    this.setUrl(url);
    this.setParentModal(parentModal);

    this.initModal();
    this.openModal();
};

ProcessOrderModal.prototype.setParentModal = function(parentModal) {
    this.parentModal = null
    if(
        parentModal !== null
        && typeof parentModal === 'object'
        && typeof parentModal.contentWindow !== 'undefined'
    ) {
        this.parentModal = parentModal;
    }

    return this;
};

ProcessOrderModal.prototype.setUrl = function(url) {
    if(typeof url !== 'string') {
        throw new Error('Er is geen `url` opgegeven.')
    }

    this.url = url;

    return this;
};

ProcessOrderModal.prototype.setSupplier = function(supplier) {
    if(typeof supplier !== 'object') {
        throw new Error('Ongeldige data voor de leverancier.')
    }

    this.supplier = supplier;

    return this;
};

ProcessOrderModal.prototype.refreshOrderList = function() {
    if(window.orderSearch) {
        window.orderSearch.search(); // refresh list.
    }
};

ProcessOrderModal.prototype.initModal = function() {
    const modal = this;
    this.contentWindow = new ContentWindow({
        width: window.innerWidth * 0.7 + 'px',
        zIndex: 2011,
        parent: this.parentModal,

        hideCallback: function() {
            modal.refreshOrderList();

            if (this.parentModal === null) {
                this.orderLock.unlockCurrent()
            }
        }.bind(this),
        afterErrorCallback: function(data, contentWindow) {
            $('.content-window-toolbar a[data-action=process_order]', $(contentWindow.elm)).attr('disabled', false);
        },
        successCallback: function(data, contentWindow) {
            const contentWindowElm = $(contentWindow.elm);
            const dataCheck = $('<div></div>').append(data);

            $('.content-window-toolbar a[data-action=process_order]', contentWindowElm).attr('disabled', false);
            if ($('.has-error', dataCheck).length > 0 || $('.alert:not(.alert-success)', dataCheck).length > 0) {
                const contentWindowBody = $('.content-window-body', contentWindowElm);
                contentWindowBody.find('.alert').remove();
                contentWindowBody.prepend($('.alert:not(.alert-success)', dataCheck)[0].outerHTML);
            } else {
                $('.content-window-toolbar a[data-action=process_order]', contentWindowElm).remove();
                contentWindow.fadeOut();
                modal.refreshOrderList();
                Order.prototype.showOrder(modal.orderId);

                showNotification({text: data.message});
            }
        },
        toolbarButtons: [{
            position: 'right',
            label: 'Verwerken',
            order: 1,
            classNames: 'btn btn-primary',
            disabled: true,
            action: 'process_order'
        }]
    });
};

ProcessOrderModal.prototype.loadSupplierOrderForm = function (url) {
    var contentWindowElm = $(this.contentWindow.elm);

    if(this.xhrFwOrderInfo) {
        this.xhrFwOrderInfo.abort();
        this.xhrFwOrderInfo = null;
    }

    this.supplierOrderFormLoader();

    this.xhrFwOrderInfo = $.get(url)
        .done(function (data) {
            $('#supplier_order_form_panel .panel-body', contentWindowElm).html($(data));
            $('a[data-action="process_order"]', contentWindowElm).removeAttr('disabled');

            this.contentWindow.applyAjaxForm();
            new CollectionCalcVat().init(contentWindowElm.find('.collection-items'));

        }.bind(this))
        .fail(function (data) {
            $('.alert-warning').find('.error-message').html(data.responseText).parent().removeClass('hidden');
        })
    ;
};

ProcessOrderModal.prototype.supplierOrderFormLoader = function() {
    var contentWindowElm = $(this.contentWindow.elm);
    var loaderElm = $('<div class="loader"></div>');

    $('#supplier_order_form_panel .panel-body', contentWindowElm).html(loaderElm);
    $('a[data-action="process_order"]', contentWindowElm).attr('disabled', 'disabled');
};


ProcessOrderModal.prototype.bindEvents = function () {
    var contentWindowElm = $(this.contentWindow.elm);

    if (this.parentModal === null) {
        this.orderLock.init({ uuid: this.orderUuid })
    }

    contentWindowElm
        .on('click', 'a[data-event="get-order"]', function (e) {
            e.preventDefault();

            var href = $(this).attr('href');
            var orderId = $(this).data('order-id');

            new Order(href, orderId);

            return false;
        })
        .on('click', 'input[data-action=select_supplier]', function (e) {
            e.preventDefault();

            var elm = $(e.target);

            // Uncheck other checkboxs and check current.
            $('#table_order_suppliers tr').removeClass('active').find('.checked').removeClass('checked');
            elm.parent().addClass('checked').closest('tr').addClass('active');

            $('a[data-action="process_order"]').show();

            // Hide errors.
            $('.alert-warning').find('.error-message').html('').parent().addClass('hidden');

            // Set current supplier.
            this.setSupplier({
                id: elm.attr('data-id'),
                name: elm.closest('tr').find('.supplier').text().trim()
            });

            this.loadSupplierOrderForm(elm.closest('span').data('url'));
        }.bind(this))
        .on('click', '[data-event="select-alternative-supplier"]', function (e) {
            e.preventDefault();

            var modal = new SelectAlternativeSupplierModal({
                url: $(this).data('modal-url'),
                order: $('a[get-order=get-order]').data('order-id'),
                width: '800px',
            });

            modal.open();
        })
        .on('click', 'a[data-action="process_order"]', function (e) {
            e.preventDefault();

            var elm = $(e.target);

            if (elm.attr('disabled')) {
                return;
            }

            elm.attr('disabled', true);
            $('form', contentWindowElm).submit();
        }.bind(this));

    // Select first supplier by default.
    $('table#table_order_suppliers tr:first-child input[data-action="select_supplier"]', contentWindowElm).trigger('click');

    $('[data-toggle=popover]', contentWindowElm).popover();

    contentWindowElm.tooltip({
        selector: '[data-popup=tooltip]'
    });
};

ProcessOrderModal.prototype.initialize = function () {
    const contentWindowElm = $(this.contentWindow.elm);

    const orderInfoElm = $('#order_info', contentWindowElm);

    this.orderId = orderInfoElm.data('order-id');
    this.orderUuid = orderInfoElm.data('order-uuid');
    this.orderNumber = orderInfoElm.data('order-number');

    this.bindEvents();
};

ProcessOrderModal.prototype.openModal = function() {
    this.contentWindow.show().loading();

    this.xhr = $.get(this.url)
        .done(function(data){
            this.contentWindow.setHtml(data);
            this.initialize();
        }.bind(this))
        .fail(function(error) {
            let errorMessage = 'Er is een fout opgetreden bij het open van de order'
            if (error.responseJSON.error !== undefined) {
                errorMessage = errorMessage + ':<br/><br/>' + error.responseJSON.error
            }
            this.contentWindow.hide(false, true);
            swal({
                title: 'Fout',
                html: errorMessage,
                type: 'error',
            });
        }.bind(this));
};

window.ProcessOrderModal = ProcessOrderModal;

