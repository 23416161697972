import { post } from 'axios';
import {standardXHRErrorHandler} from "../../standardXHRErrorHandler";
import {showNotification} from "../../toast";

/**
 * @param {string} reason
 * @param {string} type
 * @param {string} url
 */
export function cancelOrderCollection({reason, type, url}) {
    const formData = new FormData();
    formData.append('type', type);
    formData.append('reason', reason);

    return post(url, formData).then(({ data }) => {
        showNotification({ text: data.message });
        self.contentWindow.refresh();
    }).catch(standardXHRErrorHandler);
}
