export default function EditLetter(url, parameters) {

    if (typeof (ContentWindow) === 'undefined') {
        throw 'Missing ContentWindow dependency';
    }

    var options = {
        id: 'content-window-letter',
        width: '1200px',
        zIndex: 3000,
        toolbarButtons: [
            {
                id: 'letter_submit',
                position: 'right',
                label: 'Brief opslaan',
                classNames: 'btn btn-primary',
                action: 'letter-save'
            }
        ]
    };

    options = $.extend(options, parameters);

    this.contentWindow = new ContentWindow(options);

    this.open(url, options);
}

EditLetter.prototype.open = function (url, options) {
    var self = this;

    self.contentWindow.setOption('toolbarButtons', options.toolbarButtons);
    self.contentWindow.empty().show();

    $.get(url).done(function (data) {
        self.contentWindow.setHtml(data);

        self.bindEvents();
    });
};

EditLetter.prototype.bindEvents = function () {
    var self = this;

    $(this.contentWindow.elm).on('click', '[data-action="letter-save"]:not([disabled])',  function (e) {
        e.preventDefault();
        
        $('button[type="submit"]', self.contentWindow.elm).trigger('click');
    });
};
