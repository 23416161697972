import Vue from 'vue'
import Vuex from 'vuex'

import account from '@topgeschenken/webshop-admin/store/account/index'
import order from '@topgeschenken/webshop-admin/store/order'
import orderCollection from '@topgeschenken/webshop-admin/store/order/order-collection'
import batchUploads from '@topgeschenken/webshop-admin/store/order/batchUploads'
import assortment from '@topgeschenken/webshop-admin/store/catalog/assortment'
import company from '@topgeschenken/webshop-admin/store/relation/company/index'
import customer from '@topgeschenken/webshop-admin/store/relation/customer/index'
import companyEstablishment from '@topgeschenken/webshop-admin/store/relation/company/establishment'
import companyReports from '@topgeschenken/webshop-admin/store/relation/company/reports'
import companyRegistration from '@topgeschenken/webshop-admin/store/relation/company/registration'
import externalIdentityProvider from '@topgeschenken/webshop-admin/store/relation/externalIdentityProvider/index'
import companyExternalIdentity from '@topgeschenken/webshop-admin/store/relation/companyExternalIdentity/index'
import fieldMapperPreset from '@topgeschenken/webshop-admin/store/common/fieldMapper/fieldMapperPreset'
import fieldMapperPresetVersion from '@topgeschenken/webshop-admin/store/common/fieldMapper/fieldMapperPresetVersion'
import fieldMapperMapping from '@topgeschenken/webshop-admin/store/common/fieldMapper/fieldMapperMapping'
import fieldMapperMappingEntity from '@topgeschenken/webshop-admin/store/common/fieldMapper/fieldMapperMapping/entity'
import fieldMapperExternalProperty from '@topgeschenken/webshop-admin/store/common/fieldMapper/fieldMapperExternalProperty'
import manualFinalize from '@topgeschenken/webshop-admin/store/punchout/manual-finalize/index'
import machineAccount from '@topgeschenken/webshop-admin/store/security/machineAccount/index'
import voucher from '@topgeschenken/webshop-admin/store/discount/voucher/index'
import productFeeds from '@topgeschenken/webshop-admin/store/productFeeds'
import productGroup from '@topgeschenken/webshop-admin/store/catalog/product/productGroup'
import site from '@topgeschenken/webshop-admin/store/site'
import deliveryDateExceptions from '@topgeschenken/webshop-admin/store/delivery/deliveryDateExceptions'
import balance from '@topgeschenken/webshop-admin/store/order/order-collection/balance'
import common from '@topgeschenken/webshop-admin/store/common'
import mercure from '@topgeschenken/webshop-admin/store/mercure'

Vue.use(Vuex)

const SET_PRODUCTS = 'set_products'

export default new Vuex.Store({
  modules: {
    mercure: {
      namespaced: true,
      ...mercure
    },
    account: {
      namespaced: true,
      ...account
    },
    common: {
      namespaced: true,
      ...common,
      modules: {
        fieldMapper: {
          namespaced: true,
          modules: {
            fieldMapperPresetVersion: {
              namespaced: true,
              ...fieldMapperPresetVersion
            },
            fieldMapperPreset: {
              namespaced: true,
              ...fieldMapperPreset
            },
            fieldMapperMapping: {
              namespaced: true,
              ...fieldMapperMapping,
              modules: {
                entity: {
                  namespaced: true,
                  ...fieldMapperMappingEntity
                }
              }
            },
            fieldMapperExternalProperty: {
              namespaced: true,
              ...fieldMapperExternalProperty
            }
          }
        }
      }
    },
    delivery: {
      namespaced: true,
      modules: {
        deliveryDateExceptions: {
          namespaced: true,
          ...deliveryDateExceptions
        }
      }
    },
    discount: {
      namespaced: true,
      modules: {
        voucher: {
          namespaced: true,
          ...voucher
        }
      }
    },
    catalog: {
      namespaced: true,
      modules: {
        assortment: {
          namespaced: true,
          ...assortment
        },
        product: {
          namespaced: true,
          state: () => {
            return {
              list: []
            }
          },
          mutations: {
            [SET_PRODUCTS]: (state, payload) => {
              state.list = payload
            }
          },
          getters: {
            getItem: (state) => (uuid) => {
              return state.list.filter((item) => {
                return item.uuid === uuid
              })[0] || null
            }
          },
          modules: {
            productGroup: {
              namespaced: true,
              ...productGroup
            }
          }
        }
      }
    },
    productFeeds: {
      namespaced: true,
      ...productFeeds
    },
    punchout: {
      namespaced: true,
      modules: {
        'manual-finalize': {
          namespaced: true,
          ...manualFinalize
        }
      }
    },
    security: {
      namespaced: true,
      modules: {
        machineAccount: {
          namespaced: true,
          ...machineAccount
        }
      }
    },
    relation: {
      namespaced: true,
      modules: {
        companyExternalIdentity: {
          namespaced: true,
          ...companyExternalIdentity
        },
        externalIdentityProvider: {
          namespaced: true,
          ...externalIdentityProvider
        },
        customer: {
          namespaced: true,
          ...customer
        },
        company: {
          namespaced: true,
          ...company,
          modules: {
            reports: {
              namespaced: true,
              ...companyReports
            },
            registration: {
              namespaced: true,
              ...companyRegistration
            },
            establishment: {
              namespaced: true,
              ...companyEstablishment
            }
          }
        }
      }
    },
    site: {
      namespaced: true,
      ...site
    },
    order: {
      namespaced: true,
      ...order,
      modules: {
        'order-collection': {
          namespaced: true,
          ...orderCollection,
          modules: {
            'balance': {
              namespaced: true,
              ...balance
            }
          }
        },
        'batchUploads': {
          namespaced: true,
          ...batchUploads,
        }
      }
    }
  }
})
