// ************************************************************* //
// *********************** STYLING ***************************** //
// ************************************************************* //

import 'sweetalert2/dist/sweetalert2.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-slider/dist/css/bootstrap-slider.css'
import '@mdi/font/css/materialdesignicons.css'
import 'font-awesome/css/font-awesome.css'

import '../../css/icons/icomoon/styles.css'
import '../../css/bootstrap_modification.css'

import '../../css/core.css'
import '../../css/components.css'
import '../../css/colors.css'

import '../../scss/app.scss'

import '../../css/cms.css'
import '../../css/cms/form.css'
import '../../css/cms/form/elements.css'
import '../../css/cms/form/collection-list.css'
import '../../css/cms/view.css'

import '../../css/crud.css'
import '../../css/datatable.css'
import '../../css/content_window.css'
import '../../css/user_activity_history.css'
import '../../css/rule_builder.css'

import '../../css/view_pdf.css'


import '../../css/order/internal_remark.css'
import '../../css/order/list.css'
import '../../css/order/order_edit_add_product.css'
import '../../css/order/order.css'
import '../../css/modals/process_order_modal.css'

// Company CSS
import '../../css/form/company.css'
import '../../css/form/assortment.css'
import '../../css/internal_remark.css'

import '../../scss/select2.scss'

import 'jQuery-QueryBuilder/dist/css/query-builder.default.min.css'

// ************************************************************* //
// *********************** SCRIPTS ***************************** //
// ************************************************************* //

import 'regenerator-runtime/runtime'
import 'script-loader!jquery'
import 'script-loader!bootstrap/dist/js/bootstrap'
import 'script-loader!bootstrap-slider/dist/bootstrap-slider.min'
import 'script-loader!jquery-ui-sortable-only/jquery-ui.min'
import 'script-loader!sweetalert2/dist/sweetalert2'
import 'script-loader!jquery.scrollto/jquery.scrollTo'
import 'script-loader!js-sha1/build/sha1.min'
import 'script-loader!jquery-form'
import 'script-loader!fancybox/dist/js/jquery.fancybox'
import 'script-loader!pnotify/dist/umd/PNotify'
import 'script-loader!bootbox/bootbox.min'
import 'script-loader!datatables.net'
import 'script-loader!datatables.net-buttons'
import 'script-loader!datatables.net-fixedheader'
import 'script-loader!datatables.net-rowreorder'
import 'script-loader!@borayazilim/rowsorter/dist/RowSorter'
import 'script-loader!jquery.uniform/dist/js/jquery.uniform.standalone'
import 'script-loader!select2/dist/js/select2'
import 'script-loader!select2/dist/js/i18n/nl'
import 'script-loader!bootstrap-select/dist/js/bootstrap-select'
import 'script-loader!bootstrap-switch'
import 'script-loader!autosize'
import 'script-loader!typeahead.js/dist/typeahead.bundle'
import 'script-loader!bootstrap-multiselect/dist/js/bootstrap-multiselect'
import 'script-loader!jquery-form-validator/form-validator/jquery.form-validator.min'
import 'script-loader!jquery-form-validator/form-validator/lang/nl'
import 'script-loader!sortablejs'
import 'script-loader!plupload/js/plupload.full.min'
import 'script-loader!plupload/js/jquery.plupload.queue/jquery.plupload.queue'
import 'script-loader!plupload/js/i18n/nl'
import 'script-loader!pickadate/lib/picker'
import 'script-loader!pickadate/lib/picker.date'
import 'script-loader!pickadate/lib/picker.time'
import 'script-loader!pickadate/lib/translations/nl_NL'
import 'script-loader!pickadate/lib/legacy'
import 'script-loader!moment/moment'
import 'script-loader!fullcalendar/dist/fullcalendar.min'
import 'script-loader!fullcalendar/dist/locale/nl'
import 'script-loader!legit-ripple/dist/ripple.min'
import 'script-loader!daterangepicker/daterangepicker'
import 'script-loader!spectrum/spectrum'
import 'script-loader!spectrum/i18n/jquery.spectrum-nl'

import 'script-loader!jQuery-QueryBuilder/dist/js/query-builder.standalone'
import 'script-loader!jQuery-QueryBuilder/dist/i18n/query-builder.nl'

import 'script-loader!nestedSortable/jquery.mjs.nestedSortable.js'
import 'script-loader!jquery-form/dist/jquery.form.min.js'
import 'script-loader!switchery/dist/switchery.min'

// Local thirdparty scripts
import 'script-loader!./../../../../../RuleBundle/Resources/public/js/QueryBuilder/ts-daterange-picker/plugin'
import 'script-loader!./../../../../../RuleBundle/Resources/public/js/QueryBuilder/ts-bootstrap-switch/plugin'
import 'script-loader!./../libs/fab.min'
import 'script-loader!./../libs/drilldown'
import 'script-loader!./../libs/autocomplete-address'

// Application scripts
import './../images'

import 'script-loader!./../core/app.js'
import 'script-loader!./../cms/form/CollectionList.js'
import 'script-loader!./../cms/form/elements.js'
import 'script-loader!./../cms/form/element/media.js'

import 'script-loader!./../entities/ProductModal'
import 'script-loader!./../entities/PersonalizationModal'
import 'script-loader!./../entities/CardModal'
import 'script-loader!./../entities/LetterModal'

import './../utils/uuid.js'
import './../utils/copy_to_clipboard.js'
import 'script-loader!./../button_utils.js'
import './../cms.js'
import 'script-loader!./../collection_calc_vat.js'
import 'script-loader!./../content_window.js'
import 'script-loader!./../crud.js'
import 'script-loader!./../fraud.js'
import 'script-loader!./../preview.js'
import 'script-loader!./../autocomplete-address.js'

import 'script-loader!./../user_activity_history.js'
import 'script-loader!./../utils.js'
import 'script-loader!./../view-pdf.js'
import 'script-loader!./../carrier-map.js'
import 'script-loader!./../modals/dataset_filter_settings_modal.js'

import './../order/internal_remark.js'
import './../order/list.js'
import './../order/order.js'
import './../order/manualy_process_order.js'
import './../order/view_design.js'

import 'script-loader!./../../../../../RuleBundle/Resources/public/js/Rule/Create'
import 'script-loader!./../../../../../RuleBundle/Resources/public/js/Rule/Edit'
import 'script-loader!./../../../../../RuleBundle/Resources/public/js/Rule/Rule'
import 'script-loader!./../../../../../RuleBundle/Resources/public/js/Rule/Show'
import 'script-loader!./../../../../../RuleBundle/Resources/public/js/rule-builder.js'

import './../libs/sentry'

const $ = window.$
const Order = window.Order

$(document).ready(function () {
  $('ul.breadcrumb-elements').on('click', 'li.history-menu > ul> li > a[data-href]', function (e) {
    e.preventDefault()

    new Order($(this).attr('data-href'))
  })
})
