import ProductValidator from './../../validator/product_validator';

export default function AddEditProduct(props) {
    this.props = props;
    this.props.editMode = props.editMode || false;
    this.props.jQueryElements = props.jQueryElements || {};

    const initializeData = this.props.initializeData || {};

    this.data = {
        product: Object.assign(new ProductModal, initializeData.product || {}),
        card: Object.assign(new CardModal, initializeData.card || {}),
        letter: Object.assign(new LetterModal, initializeData.letter || {}),
        personalization: Object.assign(new PersonalizationModal, initializeData.personalization || {}),
    };

    this.cardList = {};
    this.contentWindow = new ContentWindow({
        width: '1280px',
        zIndex: 2011,
        parent: Order.contentWindow,
        closeButtonLabel: 'Annuleren',
        toolbarButtons: this.getToolbarButtons()
    });

    this.fetchMissingData().then(function() {
        this.boot();
    }.bind(this));
}

AddEditProduct.prototype.fetchMissingData = function () {
    return new Promise(function(resolve) {
        if(this.data.product && this.data.product.id) {
            this.getProductInformation(this.data.product.id).then(function(data) {
                const missingData = {
                    isCardAvailable: data.isCardAvailable,
                    isCombination: data.isCombination,
                    isLetterAvailable: data.isLetterAvailable,
                    isPersonalizationAvailable: data.isPersonalizationAvailable,
                    isPersonalizationRequired: data.isPersonalizationRequired,
                };

                // Add missing data
                this.data.product = Object.assign(new ProductModal, this.data.product, missingData);

                // Fix for combination with personalization
                if(this.data.product
                    && this.data.product.isCombination
                    && this.data.product.metadata.designer
                    && data.hasOwnProperty('personalization')
                ) {
                    this.getProductInformation(data.personalization.id).then(function(data) {
                        this.data.personalization = Object.assign(new ProductModal, data);
                        this.data.personalization.metadata = this.data.product.metadata;

                        resolve();
                    }.bind(this));
                } else {
                    resolve();
                }
            }.bind(this));
        } else {
            resolve();
        }
    }.bind(this))
};

AddEditProduct.prototype.boot = function () {
    this.showModal();
    this.bindEvents();
    this.checkAdditionalOptions();
};

AddEditProduct.prototype.getToolbarButtons = function () {
    return [
        {
            id: 'update_order',
            position: 'right',
            label: this.props.editMode === true ? 'Bijwerken' : 'Toevoegen',
            order: 1,
            classNames: 'btn btn-primary update-order',
            action: 'update_order'
        }
    ]
};

AddEditProduct.prototype.showModal = function () {
    this.contentWindow
        .setHtml(this.renderTemplate())
        .show()
        .hideloader();

    return this;
};

AddEditProduct.prototype.checkCardSupported = function () {
    const data = this.data || {};
    const {isCardAvailable} = data.product;
    const card = data.card|| {};

    if (isCardAvailable && card.id) {
        $('#add_card').prop('checked', true);
        this.retrieveCards();
    } else if (!isCardAvailable && card.id) {
        this.showNotification('Het gekozen product ondersteund geen kaartje.');
        $('#add_card').prop('checked', false);
        this.hideCardPanel();
    }
};

AddEditProduct.prototype.checkPersonalizationSupported = function () {
    const data = this.data || {};
    const {isPersonalizationAvailable, isPersonalizationRequired} = data.product;
    const personalization = data.personalization || {};
    const hasPersonalizationDesign = personalization.id && personalization.metadata.designer;

    if (isPersonalizationAvailable && personalization.id && hasPersonalizationDesign) {
        this.getDesignerPreviewUrl().then(function(previewUrl) {
            $('#add_personalization').prop('checked', true);
            this.showPersonalizationPreview(previewUrl);
            this.showPersonalizationPanel();
        }.bind(this))
    } else if(isPersonalizationAvailable && isPersonalizationRequired) {
        $('#add_personalization').prop('checked', true);
        this.retrievePersonalization();
    } else if (!isPersonalizationAvailable && personalization.id) {
        this.showNotification('Het gekozen product ondersteund geen ontwerp.');
        $('#add_personalization').prop('checked', false);
        this.hidePersonalizationPanel();
    }
};

AddEditProduct.prototype.checkLetterSupported = function () {
    const data = this.data || {};
    const {isLetterAvailable} = data.product;
    const letter = data.letter || {};

    if (isLetterAvailable && letter.id) {
        $('#add_letter').prop('checked', true);
        this.showLetterPanel();
    } else if (!isLetterAvailable && letter.id) {
        this.showNotification('Het gekozen product ondersteund geen brieven.');
        $('#add_letter').prop('checked', false);
        this.hideLetterPanel();
    }
};

AddEditProduct.prototype.bindEvents = function () {
    const contentWindow = $(this.contentWindow.elm);
    const options = $('.available-options', contentWindow);
    const editMode = this.props.editMode;

    // noinspection JSUnresolvedFunction
    $('#product').select2({
        placeholder: 'Kies een product',
        ajax: {
            url: this.props.searchProductUrl,
            dataType: 'json',
            delay: 250,
            placeholder: '',
            minimumInputLength: 3,
            allowClear: true,
            data: function (params) {
                return {
                    query_term: params.term,
                    edit_mode: editMode ? 1 : 0
                };
            }.bind(this),
            processResults: function (data) {
                return {
                    results: data.items
                };
            }
        }
    });

    $('#product', contentWindow).on('change', this.onChangeProduct.bind(this));
    $('.option-card', options).on('change', 'input', this.onToggleCard.bind(this));
    $('.option-letter', options).on('change', 'input', this.onToggleLetter.bind(this));
    $('.option-personaliation', options).on('change', 'input', this.onTogglePersonalization.bind(this));
    $('.card-list', contentWindow).on('change', 'input', this.onChangeCard.bind(this));
    $('.card-container textarea', contentWindow).on('keydown keyup', this.onUpdateCardText.bind(this));

    contentWindow.on('click', '[data-action="update_order"]', this.onUpdateOrder.bind(this));
    contentWindow.on('click', '.btn-save-personalisation', this.onSavePersonalization.bind(this));
    contentWindow.on('click', '.btn-edit-personalisation', this.onEditPersonalization.bind(this));

    window.addEventListener('message', function (response) {
        if (typeof (response.data.onDesignerSave) !== 'undefined') {
            const previewUrl = response.data.onDesignerSave;

            this.data.personalization = dataMerge(
                true,
                new PersonalizationModal,
                this.data.personalization, {
                    metadata: {
                        designer: grabUuidFromString(previewUrl),
                        designer_saved: + new Date()
                    }
                }
            );

            this.showPersonalizationPreview(previewUrl);

            $('[data-action="update_order"]').removeAttr('disabled');
        }
    }.bind(this));
};

AddEditProduct.prototype.checkAdditionalOptions = function() {
    this.updateProductPanelDisplay();

    this.checkCardSupported();
    this.checkPersonalizationSupported();
    this.checkLetterSupported();
};

AddEditProduct.prototype.onChangeProduct = function (e) {
    const selectedOption = $(e.currentTarget).find('option:selected');

    if (isNaN(parseInt(selectedOption.val()))) return;

    this.getProductInformation(selectedOption.val()).then(function (data) {
        this.data.product = Object.assign(new ProductModal, data);

        this.checkAdditionalOptions();
    }.bind(this));
};

AddEditProduct.prototype.getProductInformation = function (productId) {
    return $.ajax({
        url: this.props.productInfoUrl.replace('__PRODUCT__', productId),
    });
};

AddEditProduct.prototype.updateProductPanelDisplay = function () {
    const data = this.data;
    const product = data.product;
    const contentWindow = $(this.contentWindow.elm);
    const options = $('.available-options', contentWindow);
    const personalizationOptionInputElm = $('.option-personaliation input', options);
    const {isCardAvailable, isLetterAvailable, isPersonalizationAvailable, isPersonalizationRequired} = product;
    const hasLetter = data.letter && data.letter.id;
    const hasPersonalization = data.personalization && data.personalization.id;

    const showLetterOption = !!isLetterAvailable && !!hasLetter;

    $('.option-card', options).toggle(!!isCardAvailable);
    $('.option-letter', options).toggle(showLetterOption);

    if (isPersonalizationAvailable
        && personalizationOptionInputElm.length > 0) {

        $('.option-personaliation', options).show();

        if (isPersonalizationRequired) {
            personalizationOptionInputElm
                .attr('checked', true)
                .attr('disabled', true);
        } else if (isPersonalizationAvailable && hasPersonalization) {
            personalizationOptionInputElm
                .attr('disabled', false)
                .attr('checked', true);
        } else {
            personalizationOptionInputElm
                .removeAttr('disabled');
        }
    } else {
        $('.option-personaliation', options).hide();
    }

    options.toggle(!!isCardAvailable || !!isPersonalizationAvailable || !!isLetterAvailable);
};

AddEditProduct.prototype.onToggleCard = function (e) {
    const state = $(e.currentTarget).is(':checked');

    if (state) {
        this.retrieveCards();
    } else {
        this.hideCardPanel();
    }
};

AddEditProduct.prototype.onToggleLetter = function (e) {
    const state = $(e.currentTarget).is(':checked');

    if (state) {
        this.retrieveLetter();
    } else {
        this.hideLetterPanel();
    }
};

AddEditProduct.prototype.onTogglePersonalization = function (e) {
    const state = $(e.currentTarget).is(':checked');

    if (state) {
        this.retrievePersonalization(false);
    } else {
        this.hidePersonalizationPanel();
    }
};

AddEditProduct.prototype.onChangeCard = function (e) {
    const elm = $(e.currentTarget);

    if (elm.is(':checked')) {
        const cardId = elm.val();

        if(this.cardList.hasOwnProperty(cardId)) {
            this.data.card = dataMerge(true, new CardModal, this.data.card, this.cardList[cardId]);
        }

        this.updateCardDisplay();
    }
};

AddEditProduct.prototype.updateCardDisplay = function () {
    const {card} = this.data;

    if(!card.id) return false;

    const contentWindow = $(this.contentWindow.elm);
    const cardImageElm = $('#card_image', contentWindow);
    const cardPriceElm = $('.card-price', contentWindow);
    const cardImageUrl = card.image || this.cardList[card.id].image || null;
    const hasCardImageUrl = !!cardImageUrl;
    const priceIncl = '' + card.priceIncl;

    if (hasCardImageUrl) {
        cardImageElm.css({'background-image': `url(${cardImageUrl})`});
    }

    cardImageElm.toggle(hasCardImageUrl);

    cardPriceElm.html(` - &euro; ${priceIncl.replace('.', ',')}`);
};

AddEditProduct.prototype.onUpdateCardText = function (e) {
    const elm = $(e.currentTarget);
    const value = elm.val();
    const contentWindow = $(this.contentWindow.elm);

    switch (e.type) {
        case 'keydown':
            if (parseInt(elm.get(0).scrollHeight) <= parseInt(elm.outerHeight())) {
                $.data(this, 'value', value);
            }

            break;
        case 'keyup':
            const cards = this.cardList;
            const i = Object.keys(cards)[0];

            if(!this.data.card.id && cards.hasOwnProperty(i)) {
                const firstCard = cards[i];
                this.data.card = firstCard;

                $(`.radio-card[value=${firstCard.id}]`, contentWindow).prop('checked', true);
                this.updateCardDisplay();
            }

            this.data.card = dataMerge(true, new CardModal, this.data.card, {
                metadata: {
                    text: value,
                    is_card: true
                }
            });

            if (parseInt(elm.get(0).scrollHeight) > parseInt(elm.outerHeight())) {
                if ($.data(this, 'value').length > 0) {
                    elm.val($.data(this, 'value'));
                } else {
                    elm.val(value.substr(0, -1)).trigger('keyup');
                }
            }
            break;
    }
};

AddEditProduct.prototype.onEditPersonalization = function (e) {
    e.preventDefault();

    const contentWindow = $(this.contentWindow.elm);
    const panelPersonalization = $('#panel_personalization', contentWindow);
    const productId = $('#product', contentWindow).val();
    const uuid = this.data.personalization.metadata.designer || null;
    const iframe = $('iframe', panelPersonalization);

    $('[data-action="update_order"]').attr('disabled', 'disabled');

    if (typeof (uuid) !== 'undefined' && uuid !== null) {
        iframe
        .attr('src', this.buildDesignEditUrl(productId, uuid))
        .show()
        ;
        this.hidePersonalizationPreview();
    }
};

AddEditProduct.prototype.onSavePersonalization = function (e) {
    e.preventDefault();

    const contentWindow = $(this.contentWindow.elm);
    const panelPersonalization = $('#panel_personalization', contentWindow);

    $('iframe', panelPersonalization).get(0).contentWindow.postMessage({
        designerFunction: 'saveDesign',
    }, window.location.origin);
};

AddEditProduct.prototype.onUpdateOrder = function (e) {
    e.preventDefault();

    const contentWindow = $(this.contentWindow.elm);
    const orderEditList = $('#order_edit');
    const jQueryElements = this.props.jQueryElements;
    const {product, card, personalization, letter} = this.data;

    const cardIsChecked = $('#add_card', contentWindow).is(':checked');
    const personalizationIsChecked = $('#add_personalization', contentWindow).is(':checked');
    const letterIsChecked = $('#add_letter', contentWindow).is(':checked');

    $('.card-required-message, .personalization-required-message, .letter-required-message').hide();

    const productData = {};

    const productValidator = new ProductValidator('product', product);
    const cardValidator = new ProductValidator('card', card);
    const letterValidator = new ProductValidator('letter', letter);
    const personalizationValidator = new ProductValidator('personalization', personalization);

    if(!productValidator.isValid()) {
        this.showNotification('Ongeldig product gekozen');
        return;
    }

    productData.product = product;

    if (cardIsChecked) {
        if (card.id !== null) {
            if(!cardValidator.isValid()) {
                $('.card-required-message').html(cardValidator.getErrors().join('<br/>')).show();
                return;
            } else {
                productData.card = card;
            }
        } else {
            $('.card-required-message').html('Geen kaartje gekozen').show();
            return;
        }
    }

    if (personalizationIsChecked) {
        if(personalization.id !== null) {
            if (product.isPersonalizationRequired && !personalizationValidator.isValid()) {
                $('.personalization-required-message').show();
                return;
            } else if (product.isPersonalizationRequired
                && (product.hasOwnProperty('isCombination') && product.isCombination === true)
                && !productValidator.isValid()) {
                $('.personalization-required-message').html(personalizationValidator.getErrors().join('<br/>')).show();
            } else {
                if(product.hasOwnProperty('isCombination') && product.isCombination === true) {
                    productData.product.metadata = personalization.metadata; // only assign metadata (designer: <uuid>)
                } else {
                    productData.personalization = personalization;
                }
            }
        } else {
            $('.personalization-required-message').html('Geen personalisatie gekozen').show();
            return;
        }
    }

    if (letterIsChecked) {
        if(letter.id !== null) {
            if (!letterValidator.isValid()) {
                $('.letter-required-message').html(letterValidator.getErrors().join('<br/>')).show();
                return;
            } else {
                productData.letter = letter;
            }
        } else {
            $('.letter-required-message').html('Geen brief gekozen').show();
            return;
        }
    }

    if(orderEditList.length > 0) {
        orderEditList[0].dispatchEvent(new CustomEvent('product:add_update_order_line', {detail: {
            productData: productData,
            jQueryElements: jQueryElements
        }}));

        this.contentWindow.hide();
    }
};

AddEditProduct.prototype.retrieveCards = function () {
    const contentWindow = $(this.contentWindow.elm);
    const product = $('#product', contentWindow);
    const panelCard = $('#panel_card', contentWindow);

    this.showCardPanel();

    $.ajax({
        url: this.props.availableCardsUrl.replace('__PRODUCT__', product.val()),
    }).done(function (cards) {
        this.cardList = cards;

        $('.card-list', panelCard).html(this.renderCardList());
    }.bind(this));
};

AddEditProduct.prototype.retrieveLetter = function () {
    const contentWindow = $(this.contentWindow.elm);
    const product = $('#product', contentWindow);

    this.showLetterPanel();

    $.ajax({
        url: this.props.availableLettersUrl.replace('__PRODUCT__', product.val()),
    }).done(function (data) {
        this.data.letter = dataMerge(true, new LetterModal, this.data.letter, data);
    }.bind(this));
};

AddEditProduct.prototype.showLetterPanel = function () {
    const contentWindow = $(this.contentWindow.elm);
    const panelLetter = $('#panel_letter', contentWindow);

    panelLetter.show();
};

AddEditProduct.prototype.hideLetterPanel = function () {
    const contentWindow = $(this.contentWindow.elm);
    const panelLetter = $('#panel_letter', contentWindow);

    panelLetter.hide();
};

AddEditProduct.prototype.renderCardList = function () {
    const html = [];
    const cards = this.cardList;

    for (const cardId in cards) {
        if (cards.hasOwnProperty(cardId)) {
            const card = cards[cardId];
            const attrChecked = card.id === this.data.card.id ? 'checked' : '';

            html.push(`<div class="line-radio">
                <input type="radio" value="${card.id}" id="radio_card_${card.id}"
                   class="radio-card"
                   name="radio-card"
                   ${attrChecked}
               />
                <label for="radio_card_${card.id}">
                    ${card.name}
                </label>
            </div>`);
        }
    }

    this.updateCardDisplay();

    return html.join('');
};

AddEditProduct.prototype.showCardPanel = function () {
    const contentWindow = $(this.contentWindow.elm);
    const panelCard = $('#panel_card', contentWindow);

    panelCard.show();
};

AddEditProduct.prototype.hideCardPanel = function () {
    const contentWindow = $(this.contentWindow.elm);
    const panelCard = $('#panel_card', contentWindow);

    $('.card-list', panelCard).empty();
    $('.card-price', '#panel_card', contentWindow).html('');

    panelCard.hide();
};

AddEditProduct.prototype.retrievePersonalization = function () {
    const contentWindow = $(this.contentWindow.elm);
    const product = $('#product', contentWindow);
    const productId = product.val();
    const panelPersonalization = $('#panel_personalization', contentWindow);

    this.showPersonalizationPanel();

    $.ajax({
        url: this.props.availablePersonalizationsUrl.replace('__PRODUCT__', productId),
    }).done(function (data) {
        const currentData = this.props.data || {};
        const existingPersonalization = currentData.personalization || {};
        const hasExistingPersonalization = existingPersonalization.id && existingPersonalization.metadata.designer;

        this.data.personalization = dataMerge(true, new PersonalizationModal, this.data.personalization, data);

        if (hasExistingPersonalization && existingPersonalization.id !== data.id) {
            this.showNotification('Let op: Ontwerp is met een andere personalisatie product gemaakt.');
        }

        if (hasExistingPersonalization) {
            this.getDesignerPreviewUrl().then(function(previewUrl) {
                this.showPersonalizationPreview(previewUrl);
            }.bind(this));
        } else {
            $('iframe', panelPersonalization)
            .attr('src', this.props.addDesignUrl.replace('__PRODUCT__', productId))
            .show();

            this.hidePersonalizationPreview();
        }
    }.bind(this));
};

AddEditProduct.prototype.showNotification = function (message, title, type) {
    title = typeof type === 'string' ? type : 'Let op!';
    type = typeof type === 'string' ? type : 'warning';

    swal(title, message, type);
};

AddEditProduct.prototype.hidePersonalizationPreview = function () {
    const contentWindow = $(this.contentWindow.elm);
    const personalizationPreview = $('.personalization-preview', contentWindow);

    $('.btn-save-personalisation', contentWindow).removeAttr('disabled').show();
    $('.btn-edit-personalisation', contentWindow).hide().attr('disabled', 'disabled');

    personalizationPreview.hide();
};

AddEditProduct.prototype.getDesignerPreviewUrl = function() {
    return $.ajax({
        url: this.props.designerPreviewUrl.replace('__UUID__', this.data.personalization.metadata.designer)
    });
};

AddEditProduct.prototype.showPersonalizationPreview = function (previewUrl) {
    const contentWindow = $(this.contentWindow.elm);
    const panelPersonalization = $('#panel_personalization', contentWindow);
    const personalizationPreview = $('.personalization-preview', contentWindow);
    const personalizationPreviewImg = $('img', personalizationPreview);

    $('.btn-edit-personalisation', contentWindow).removeAttr('disabled').show();
    $('.btn-save-personalisation', contentWindow).hide().attr('disabled', 'disabled');

    $('iframe', panelPersonalization).removeAttr('src');
    $('iframe', panelPersonalization).hide();

    personalizationPreviewImg.attr('src', previewUrl);
    personalizationPreview.show();
};

AddEditProduct.prototype.buildDesignEditUrl = function (productId, uuid) {
    const disableOrderEdit = parseInt($('input[name="order[disableOrderEdit]"]'));
    const clone = disableOrderEdit !== 1 ? '1' : '0';

    return this.props.editDesignUrl
        .replace('__PRODUCT__', productId)
        .replace('__UUID__', uuid)
        .replace('__CLONE__', clone);
};

AddEditProduct.prototype.showPersonalizationPanel = function () {
    const contentWindow = $(this.contentWindow.elm);
    const panelPersonalization = $('#panel_personalization', contentWindow);

    panelPersonalization.show();
};

AddEditProduct.prototype.hidePersonalizationPanel = function () {
    const contentWindow = $(this.contentWindow.elm);
    const panelPersonalization = $('#panel_personalization', contentWindow);

    panelPersonalization.hide();
};

AddEditProduct.prototype.renderTemplate = function () {
    const {product, card, letter, personalization} = this.data;

    return `<div class="order-edit-add-product"
         id="order_edit_add_product"
    >
        <div class="view">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="panel-title">Product toevoegen</div>
                </div>
    
                <div class="panel-body">
                    <select class="form-control" id="product">
                        ${product.id ? `<option value="${product.id}">${product.name}</option>` : ''}          
                    </select>
    
                    <div class="available-options">
                        <p><strong>Beschikbare opties</strong></p>
    
                        <div class="line-checkbox option-card" id="option_card">
                            <input id="add_card" name="add_card" type="checkbox" value="1"
                               ${card.id ? `checked="checked"` : ''}
                               >
    
                            <label for="add_card">
                                Kaartje toevoegen
                            </label>
                        </div>
    
                        <div class="line-checkbox option-personaliation" id="option_design">
                            <input id="add_personalization" name="add_personalization" type="checkbox" value="1"
                                ${personalization.id ? `checked="checked"` : ''}
                                >
    
                            <label for="add_personalization">
                                Ontwerp toevoegen
                            </label>
                        </div>
    
                        <div class="line-checkbox option-letter" ${!letter.id ? 'style="display: none;"' : ''} id="option_letter">
                            <input id="add_letter" name="add_letter" type="checkbox" value="1"
                                ${letter.id ? `checked="checked"` : ''}
                                >
                                
                            <label for="add_letter">
                                Brief toevoegen
                            </label>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="panel panel-default panel-card" id="panel_card">
                <div class="panel-heading">
                    <div class="panel-title">Kaartje toevoegen <span class="card-price"></span></div>
                    <div class="card-required-message" style="color: #d9534f; display: none;"></div>
                </div>
    
                <div class="panel-body">
                    <div class="card-container">
                        <div class="card-list"></div>
    
                        <div class="card-text">
                            <textarea
                                    name="card_text"
                                    rows="5"
                                    placeholder="Type hier uw persoonlijke tekst. Vergeet niet de afzender te vermelden"
                            >${card.metadata && card.metadata.text ? card.metadata.text : ''}</textarea>
    
                            <div id="card_image" class="card-image">
    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="panel panel-default panel-personalization" id="panel_personalization">
                <div class="panel-heading clearfix">
                    <div class="panel-title pull-left">Personalisatie toevoegen</div>
                    <div class="personalization-required-message" style="color: #d9534f; display: none;"></div>
                    <div class="pull-right">
                        <button disabled class="btn btn-xs btn-primary btn-save-personalisation">
                            <i class="fa fa-floppy-o" aria-hidden="true"></i> 
                            <span>Ontwerp opslaan</span>
                        </button>
                        <button disabled class="btn btn-xs btn-primary btn-edit-personalisation">
                            <i class="fa fa-check" aria-hidden="true"></i>
                            <span>Ontwerp bewerken</span>
                        </button>
                    </div>
                </div>
    
                <div class="panel-body">
                    <div class="personalization-container">
                        <iframe class="add-product-designer-window"></iframe>
                        <div class="personalization-preview" style="display:none;">
                            <img src="" alt="preview" />
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="panel panel-default panel-letter" id="panel_letter">
                <div class="panel-heading">
                    <div class="panel-title">Brief</div>
                    <div class="letter-required-message" style="color: #d9534f; display: none;"></div>
                </div>
    
                <div class="panel-body">
                    <div class="letter-container">
                        ${letter.id && letter.metadata.hasOwnProperty('letter') ? `<p>De samenstelling bevat een brief.</p>
                        <p>Bestandsnaam: ${letter.metadata.letter.filename}</p>` : ''}
                    </div>
                </div>
            </div>
        </div>
    </div>`;
};
