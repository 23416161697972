export function swalConfirmDelete(props = {}) {
    return Swal.delete(props);
}

export function swalConfirm({text, ...props}) {
    return Swal.fire({text, ...props });
}

export function swalConfirmHtml({html, ...props}) {
    return Swal.fire({html, ...props });
}

export class Swal {
    /**
     * @param {object} props
     *
     * @returns {Q.Promise<any> | Function | any}
     */
    static delete(props = {}) {
        const config = {
            title: 'Item verwijderen?',
            text: 'Weet je het zeker dat dit item verwijderd mag worden?',
            confirmButtonColor: '#ef5350',
            confirmButtonText: 'Ja, verwijderen!',
            ...props
        };

        return Swal.fire(config);
    }

    /**
     * @param {object} props
     *
     * @returns {Q.Promise<any> | Function | any}
     */
    static confirm(props = {}) {
        const config = {
            title: 'Weet je het zeker?',
            confirmButtonText: 'Doorgaan',
            cancelButtonText: 'Annuleren',
            ...props
        };

        return Swal.fire(config);
    }

    /**
     * @param {string} title
     * @param {string} type
     * @param {object} props
     *
     * @returns {Q.Promise<any> | Function | any}
     */
    static fire({title, type = 'warning', ...props}) {
        const config = {
            title,
            type,
            showCancelButton: true,
            reverseButtons: true,
            ...props
        };

        if(undefined === props.html && undefined === props.text) {
            throw new Error("Swal property 'text' or 'html' required");
        }

        return window.swal(config).catch(window.swal.noop);
    }
}
