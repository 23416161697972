import Vue from 'vue'
import vuetify from './vuetify'
import './../plugin-loader'
import store from './../store'
import router from './../router'

router.push = (routeName) => {
  window.location = '/admin' + router.match(routeName).fullPath
}

export function initializeVueComponent(selector = null, component = null, props = {}, setup = {}) {
  const vm = new Vue({
    vuetify,
    store,
    router,
    ...setup,
    render(h) {
      if (component) {
        return h(component, {
          props: (function () {
            const attributes = this.$el.attributes
            let initialProps = {}

            if (typeof attributes['data-initial-props'] === 'object' && typeof attributes['data-initial-props'].value === 'string') {
              initialProps = JSON.parse(attributes['data-initial-props'].value)
            }

            return {
              ...initialProps,
              ...props
            }
          }.bind(this))()
        })
      }

      return null
    }
  });

  if (selector !== null) {
    vm.$mount(selector)
  }

  return vm;
}
