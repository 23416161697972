export default function SupplierManagerInsightsModal(orderId) {
    const url = `/admin/supplier-insight/suppliers-for-delivery/${orderId}`;
    const contentWindow = new ContentWindow({
        width: '880px',
        zIndex: 2020
    });

    contentWindow.empty().show();
    $.get(url)
        .done(data => {
            contentWindow.setHtml(data);
        })
        .fail(error => {
            console.error(error);
            alert('Er ging iets fout, zie console');
        })
    ;
}
