var processed = 0;
var delivered = 0;

export default function ManuallyProcessOrder(url) {
    this.contentWindow = new ContentWindow({
        width: window.innerWidth * 0.4 + 'px',
        zIndex: 2011,
        closeButtonLabel: 'Annuleren',
        parent: Order.contentWindow,
        toolbarButtons: [
            {
                position: 'right',
                label: 'Verwerk',
                order: 1,
                classNames: 'btn btn-primary',
                action: 'manually_process',
                disabled: true,
            }
        ]
    });

    this.open(url);
}

ManuallyProcessOrder.prototype.open = function (url) {
    var self = this;

    this.url = url;
    this.contentWindow.empty().show();

    $.get(url).done(function (data) {
        self.contentWindow.setHtml(data);
        self.initialize();
    });
};

ManuallyProcessOrder.prototype.initialize = function () {
    this.bindEvents();
};

ManuallyProcessOrder.prototype.bindEvents = function () {
    var self = this;
    var manuallyProcess = $('[data-action="manually_process"]');
    var contentWindow = $(this.contentWindow.elm);

    contentWindow.unbind();
    contentWindow.on('click', '[data-action="processable"]', function (e) {
        manuallyProcess.attr('disabled', 'disabled');

        if ($(this).data('disabled') !== 1) {
            if ($(this).find('span').hasClass('checked')) {
                $(this).find('span').removeClass('checked').find('input[type="checkbox"]').removeAttr('checked');
            } else {
                $(this).find('span').addClass('checked').find('input[type="checkbox"]').attr('checked', 'checked');
            }
        }

        if ($('input[name="processed"]').attr('checked') || $('input[name="delivered"]').attr('checked')) {
            manuallyProcess.removeAttr('disabled');
        }
    });

    if ($('input[name="processed"]').attr('checked') || $('input[name="delivered"]').attr('checked')) {
        manuallyProcess.removeAttr('disabled');
    }

    contentWindow.on('click', '[data-action="manually_process"]', function (e) {
        if ($('input[name="processed"]').attr('checked')) {
            processed = 1;
        } else {
            processed = 0;
        }

        if ($('input[name="delivered"]').attr('checked')) {
            delivered = 1;
        } else {
            delivered = 0;
        }

        self.process();
    });

    $('textarea').on('click', function () {
        var tdate = new Date();
        var dd = tdate.getDate(); //yields day
        var MM = tdate.getMonth(); //yields month
        var yyyy = tdate.getFullYear(); //yields year
        var date = (dd < 10 ? '0' + dd : dd) + '-' + ((MM + 1) < 10 ? '0' + (MM + 1) : (MM + 1)) + '-' + yyyy;
        $(this).html('[' + date + '] ');
    });
};

ManuallyProcessOrder.prototype.process = function () {
    var alertElement = $('.alert');
    var url = $('form[name="manually-process"]').attr('action');
    var remark = $('textarea[name="internal_remark"]').val();

    alertElement.addClass('hidden').removeClass('alert-warning').removeClass('alert-success').html(null);

    if (!remark) {
        alertElement.addClass('alert-warning').html('Opmerkingen veld mag niet leeg zijn').removeClass('hidden');
    } else {
        $.ajax({
            url: url,
            method: 'POST',
            data: {
                'internal_remark': remark,
                'processed': processed,
                'delivered': delivered,
            }
        }).done(function (data, status, xhr) {
            alertElement.addClass('alert-success').html(data.message).removeClass('hidden');
            $('button[data-action="manually_process"]').attr('disabled', 'disabled');
        }).fail(console.error);
    }
};

window.ManuallyProcessOrder = ManuallyProcessOrder;
