import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* ************************************************************ */
/* Copied of frontends repo admin/.nuxt/router.js (and modified) */
/* ************************************************************ */

const _3d1849a8 = () => import('@topgeschenken/webshop-admin/pages/404/index.vue' /* webpackChunkName: "pages/404/index" */)
const _24336ada = () => import('@topgeschenken/webshop-admin/pages/login.vue' /* webpackChunkName: "pages/login" */)
const _b48bb7aa = () => import('@topgeschenken/webshop-admin/pages/product-feeds/index.vue' /* webpackChunkName: "pages/product-feeds/index" */)
const _5f7af0fa = () => import('@topgeschenken/webshop-admin/pages/instellingen/machine-accounts/index.vue' /* webpackChunkName: "pages/instellingen/machine-accounts/index" */)
const _3cb5c93e = () => import('@topgeschenken/webshop-admin/pages/klanten/bedrijven/index.vue' /* webpackChunkName: "pages/klanten/bedrijven/index" */)
const _70e4d663 = () => import('@topgeschenken/webshop-admin/pages/kortingen/voucher-tool/index.vue' /* webpackChunkName: "pages/kortingen/voucher-tool/index" */)
const _585fc6f6 = () => import('@topgeschenken/webshop-admin/pages/product-feeds/nieuw.vue' /* webpackChunkName: "pages/product-feeds/nieuw" */)
const _1fb975aa = () => import('@topgeschenken/webshop-admin/pages/sales/orders/index.vue' /* webpackChunkName: "pages/sales/orders/index" */)
const _71a39120 = () => import('@topgeschenken/webshop-admin/pages/instellingen/field-mapper/presets/index.vue' /* webpackChunkName: "pages/instellingen/field-mapper/presets/index" */)
const _034f0046 = () => import('@topgeschenken/webshop-admin/pages/instellingen/machine-accounts/nieuw.vue' /* webpackChunkName: "pages/instellingen/machine-accounts/nieuw" */)
const _291501da = () => import('@topgeschenken/webshop-admin/pages/punchout/bestellingen/handmatig-verwerken/index.vue' /* webpackChunkName: "pages/punchout/bestellingen/handmatig-verwerken/index" */)
const _87f99844 = () => import('@topgeschenken/webshop-admin/pages/punchout/bestellingen/open-oci-orders/index.vue' /* webpackChunkName: "pages/punchout/bestellingen/open-oci-orders/index" */)
const _1577a06c = () => import('@topgeschenken/webshop-admin/pages/instellingen/field-mapper/presets/nieuw.vue' /* webpackChunkName: "pages/instellingen/field-mapper/presets/nieuw" */)
const _4bec588a = () => import('@topgeschenken/webshop-admin/pages/instellingen/field-mapper/presets/_uuid.vue' /* webpackChunkName: "pages/instellingen/field-mapper/presets/_uuid" */)
const _4c08d9a8 = () => import('@topgeschenken/webshop-admin/pages/klanten/bedrijven/punchout/_uuid.vue' /* webpackChunkName: "pages/klanten/bedrijven/punchout/_uuid" */)
const _90647436 = () => import('@topgeschenken/webshop-admin/pages/klanten/bedrijven/registratie/_uuid.vue' /* webpackChunkName: "pages/klanten/bedrijven/registratie/_uuid" */)
const _94de5112 = () => import('@topgeschenken/webshop-admin/pages/klanten/bedrijven/vestigingen/_uuid.vue' /* webpackChunkName: "pages/klanten/bedrijven/vestigingen/_uuid" */)
const _c323ab8a = () => import('@topgeschenken/webshop-admin/pages/punchout/bestellingen/handmatig-verwerken/_uuid.vue' /* webpackChunkName: "pages/punchout/bestellingen/handmatig-verwerken/_uuid" */)
const _65c1e74b = () => import('@topgeschenken/webshop-admin/pages/instellingen/machine-accounts/_uuid.vue' /* webpackChunkName: "pages/instellingen/machine-accounts/_uuid" */)
const _77247b29 = () => import('@topgeschenken/webshop-admin/pages/klanten/bedrijven/_uuid.vue' /* webpackChunkName: "pages/klanten/bedrijven/_uuid" */)
const _916f8228 = () => import('@topgeschenken/webshop-admin/pages/klanten/bedrijven/_uuid/index.vue' /* webpackChunkName: "pages/klanten/bedrijven/_uuid/index" */)
const _52bc0ef7 = () => import('@topgeschenken/webshop-admin/pages/klanten/bedrijven/_uuid/index/api.vue' /* webpackChunkName: "pages/klanten/bedrijven/_uuid/index/api" */)
const _0172cc6c = () => import('@topgeschenken/webshop-admin/pages/klanten/bedrijven/_uuid/index/bestelde-producten.vue' /* webpackChunkName: "pages/klanten/bedrijven/_uuid/index/bestelde-producten" */)
const _54f1f334 = () => import('@topgeschenken/webshop-admin/pages/klanten/bedrijven/_uuid/index/contacten.vue' /* webpackChunkName: "pages/klanten/bedrijven/_uuid/index/contacten" */)
const _68b76ab1 = () => import('@topgeschenken/webshop-admin/pages/klanten/bedrijven/_uuid/index/dashboard.vue' /* webpackChunkName: "pages/klanten/bedrijven/_uuid/index/dashboard" */)
const _5e9d24ae = () => import('@topgeschenken/webshop-admin/pages/klanten/bedrijven/_uuid/voorgedefinieerde-koppelingen/bewerken.vue' /* webpackChunkName: "pages/klanten/bedrijven/_uuid/voorgedefinieerde-koppelingen/bewerken" */)
const _7df8b3c9 = () => import('@topgeschenken/webshop-admin/pages/klanten/bedrijven/_uuid/bewerken.vue' /* webpackChunkName: "pages/klanten/bedrijven/_uuid/bewerken" */)
const _639cda92 = () => import('@topgeschenken/webshop-admin/pages/sales/orders/_id.vue' /* webpackChunkName: "pages/sales/orders/_id" */)
const _47220836 = () => import('@topgeschenken/webshop-admin/pages/product-feeds/_uuid/index.vue' /* webpackChunkName: "pages/product-feeds/_uuid/index" */)
const _5a8bc7c3 = () => import('@topgeschenken/webshop-admin/pages/index.vue' /* webpackChunkName: "pages/index" */)
const _6b4da51c = () => import('@topgeschenken/webshop-admin/pages/instellingen/company-external-identities/index.vue' /* webpackChunkName: "pages/instellingen/company-external-identities/index" */)
const _1c72e079 = () => import('@topgeschenken/webshop-admin/pages/instellingen/external-identity-providers/index.vue' /* webpackChunkName: "pages/instellingen/external-identity-providers/index" */)
const _0f21b468 = () => import('@topgeschenken/webshop-admin/pages/instellingen/company-external-identities/nieuw.vue' /* webpackChunkName: "pages/instellingen/company-external-identities/nieuw" */)
const _4a88d8d3 = () => import('@topgeschenken/webshop-admin/pages/instellingen/external-identity-providers/nieuw.vue' /* webpackChunkName: "pages/instellingen/external-identity-providers/nieuw" */)
const _5fd88d3a = () => import('@topgeschenken/webshop-admin/pages/instellingen/company-external-identities/_uuid.vue' /* webpackChunkName: "pages/instellingen/company-external-identities/_uuid" */)
const _9c1b7f7e = () => import('@topgeschenken/webshop-admin/pages/instellingen/external-identity-providers/_uuid.vue' /* webpackChunkName: "pages/instellingen/external-identity-providers/_uuid" */)
const _422aa8a6 = () => import('@topgeschenken/webshop-admin/pages/instellingen/field-mapper/presets/index.vue' /* webpackChunkName: "pages/instellingen/field-mapper/presets/index" */)
const _746a0b75 = () => import('@topgeschenken/webshop-admin/pages/instellingen/field-mapper/presets/_uuid.vue' /* webpackChunkName: "pages/instellingen/field-mapper/presets/_uuid" */)
const _0d00a407 = () => import('@topgeschenken/webshop-admin/pages/instellingen/field-mapper/presets/nieuw.vue' /* webpackChunkName: "pages/instellingen/field-mapper/presets/nieuw" */)
const _0dde97c4 = () => import('@topgeschenken/webshop-admin/pages/email/preview/index.vue' /* webpackChunkName: "pages/email/preview/index" */)
const _7120354d = () => import('@topgeschenken/webshop-admin/pages/sales/orders/index' /* webpackChunkName: "pages/sales/orders/index" */)
const _529245a3 = () => import('@topgeschenken/webshop-admin/pages/sales/batch-orders/index.vue' /* webpackChunkName: "pages/sales/batch-orders/index" */)
const _feaf8406 = () => import('@topgeschenken/webshop-admin/pages/sales/batch-orders/nieuw.vue' /* webpackChunkName: "pages/sales/batch-orders/nieuw" */)
const _2fdcb52a = () => import('@topgeschenken/webshop-admin/pages/sales/batch-orders/_uuid.vue' /* webpackChunkName: "pages/sales/batch-orders/_uuid" */)

const _6464582c = () => import('@topgeschenken/webshop-admin/pages/instellingen/delivery-date-exceptions/index.vue' /* webpackChunkName: "pages/instellingen/delivery-date-exceptions/index" */)
const _db0b5ef4 = () => import('@topgeschenken/webshop-admin/pages/instellingen/delivery-date-exceptions/nieuw.vue' /* webpackChunkName: "pages/instellingen/delivery-date-exceptions/nieuw" */)
const _0c389018 = () => import('@topgeschenken/webshop-admin/pages/instellingen/delivery-date-exceptions/_uuid.vue' /* webpackChunkName: "pages/instellingen/delivery-date-exceptions/_uuid" */)

const routes = [{
  path: '/404',
  component: _3d1849a8,
  meta: {},
  name: '404'
}, {
  path: '/login',
  component: _24336ada,
  meta: { 'requireAuth': false, 'layout': 'clean' },
  name: 'login'
}, {
  path: '/product-feeds',
  component: _b48bb7aa,
  meta: {},
  name: 'product-feeds'
}, {
  path: '/instellingen/machine-accounts',
  component: _5f7af0fa,
  meta: { 'name': 'MachineAccountPage' },
  name: 'instellingen-machine-accounts'
}, {
  path: '/klanten/bedrijven',
  component: _3cb5c93e,
  meta: { 'name': 'CompanySummaryPage' },
  name: 'klanten-bedrijven'
}, {
  path: '/kortingen/voucher-tool',
  component: _70e4d663,
  meta: { 'name': 'VoucherToolPage' },
  name: 'kortingen-voucher-tool'
}, {
  path: '/product-feeds/nieuw',
  component: _585fc6f6,
  meta: {},
  name: 'product-feeds-nieuw'
}, {
  path: '/sales/orders',
  component: _1fb975aa,
  meta: {},
  name: 'sales-orders'
}, {
  path: '/instellingen/field-mapper/presets',
  component: _71a39120,
  meta: { 'name': 'FieldMapperPresetOverview' },
  name: 'instellingen-field-mapper-presets'
}, {
  path: '/instellingen/machine-accounts/nieuw',
  component: _034f0046,
  meta: {},
  name: 'instellingen-machine-accounts-nieuw'
}, {
  path: '/punchout/bestellingen/handmatig-verwerken',
  component: _291501da,
  meta: { 'name': 'PunchoutManualFinalizeOverview' },
  name: 'punchout-bestellingen-handmatig-verwerken'
}, {
  path: '/punchout/bestellingen/open-oci-orders',
  component: _87f99844,
  meta: { 'name': 'PunchoutManualFinalizeOpenOciOrders' },
  name: 'punchout-bestellingen-open-oci-orders'
}, {
  path: '/instellingen/field-mapper/presets/nieuw',
  component: _1577a06c,
  meta: {},
  name: 'instellingen-field-mapper-presets-nieuw'
}, {
  path: '/instellingen/field-mapper/presets/:uuid',
  component: _4bec588a,
  meta: { 'name': 'FieldMapperPresetDetail' },
  name: 'instellingen-field-mapper-presets-uuid'
}, {
  path: '/klanten/bedrijven/punchout/:uuid?',
  component: _4c08d9a8,
  meta: { 'name': 'PunchoutApiPage' },
  name: 'klanten-bedrijven-punchout-uuid'
}, {
  path: '/klanten/bedrijven/registratie/:uuid?',
  component: _90647436,
  meta: { 'name': 'CompanyRegistrationPage' },
  name: 'klanten-bedrijven-registratie-uuid'
}, {
  path: '/klanten/bedrijven/vestigingen/:uuid?',
  component: _94de5112,
  meta: { 'name': 'CompanyEstablishmentPage' },
  children: [{
    path: 'open-gesloten-periodes',
    component: _916f8228,
    name: 'klanten-bedrijven-vestigingen-uuid'
  }]
}, {
  path: '/punchout/bestellingen/handmatig-verwerken/:uuid?',
  component: _c323ab8a,
  meta: { 'name': 'PunchoutManualFinalizeDetail' },
  name: 'punchout-bestellingen-handmatig-verwerken-uuid'
}, {
  path: '/instellingen/machine-accounts/:uuid?',
  component: _65c1e74b,
  meta: {},
  name: 'instellingen-machine-accounts-uuid'
}, {
  path: '/klanten/bedrijven/:uuid',
  component: _77247b29,
  meta: { 'name': 'CompanyDetailPage' },
  children: [{
    path: '',
    component: _916f8228,
    name: 'klanten-bedrijven-uuid',
    children: [{
      path: 'api',
      component: _52bc0ef7,
      name: 'klanten-bedrijven-uuid-index-api'
    }, {
      path: 'bestelde-producten',
      component: _0172cc6c,
      name: 'klanten-bedrijven-uuid-index-bestelde-producten'
    }, {
      path: 'contacten',
      component: _54f1f334,
      name: 'klanten-bedrijven-uuid-index-contacten'
    }, {
      path: 'dashboard',
      component: _68b76ab1,
      name: 'klanten-bedrijven-uuid-index-dashboard'
    }]
  }, {
    path: 'bewerken',
    component: _7df8b3c9,
    name: 'klanten-bedrijven-uuid-bewerken'
  }, {
    path: 'voorgedefinieerde-koppelingen/bewerken',
    component: _5e9d24ae,
    name: 'klanten-bedrijven-uuid-voorgedefinieerde-koppelingen-bewerken'
  }]
}, {
  path: '/sales/orders/:id',
  component: _639cda92,
  meta: {},
  name: 'sales-orders-id'
}, {
  path: '/product-feeds/:uuid',
  component: _47220836,
  meta: {},
  name: 'product-feeds-uuid'
}, {
  path: '/',
  component: _5a8bc7c3,
  meta: {},
  name: 'index'
}, {
  path: '/instellingen/company-external-identities',
  component: _6b4da51c,
  meta: { 'name': 'CompanyExternalIdentityPage' },
  name: 'instellingen-company-external-identities'
}, {
  path: '/instellingen/external-identity-providers',
  component: _1c72e079,
  meta: { 'name': 'ExternalIdentityProviderPage' },
  name: 'instellingen-external-identity-providers'
}, {
  path: '/instellingen/company-external-identities/nieuw',
  component: _0f21b468,
  meta: {},
  name: 'instellingen-company-external-identities-nieuw'
}, {
  path: '/instellingen/external-identity-providers/nieuw',
  component: _4a88d8d3,
  meta: {},
  name: 'instellingen-external-identity-providers-nieuw'
}, {
  path: '/instellingen/company-external-identities/:uuid?',
  component: _5fd88d3a,
  meta: {},
  name: 'instellingen-company-external-identities-uuid'
}, {
  path: '/instellingen/external-identity-providers/:uuid?',
  component: _9c1b7f7e,
  meta: {},
  name: 'instellingen-external-identity-providers-uuid'
}, {
  path: '/instellingen/field-mapper/presets',
  component: _422aa8a6,
  meta: {},
  name: 'instellingen-field-mapper-presets'
}, {
  path: '/instellingen/field-mapper/presets/:uuid?',
  component: _746a0b75,
  meta: {},
  name: 'instellingen-field-mapper-presets-uuid'
}, {
  path: '/instellingen/field-mapper/presets/nieuw',
  component: _0d00a407,
  meta: {},
  name: 'instellingen-field-mapper-presets-nieuw'
}, {
  path: '/klanten/bedrijven/:uuid/bekijken',
  meta: {},
  name: 'klanten-bedrijven-uuid'
}, {
  path: '/sales/orders',
  component: _7120354d,
  meta: {},
  name: 'sales-orders'
}, {
  path: '/email/preview',
  component: _0dde97c4,
  meta: {},
  name: 'email-preview'
}, {
  path: '/sales/batch-orders',
  component: _529245a3,
  meta: {},
  name: 'sales-batch-orders'
}, {
  path: '/sales/batch-orders/nieuw',
  component: _feaf8406,
  meta: {},
  name: 'sales-batch-orders-nieuw'
}, {
  path: '/sales/batch-orders/:uuid?',
  component: _2fdcb52a,
  meta: {},
  name: 'sales-batch-orders-uuid'
}, {
  path: '/instellingen/delivery-date-exceptions',
  component: _6464582c,
  meta: {},
  name: 'instellingen-delivery-date-exceptions'
}, {
  path: '/instellingen/delivery-date-exceptions/nieuw',
  component: _db0b5ef4,
  meta: {},
  name: 'instellingen-delivery-date-exceptions-nieuw'
}, {
  path: '/instellingen/delivery-date-exceptions/:uuid?',
  component: _0c389018,
  meta: {},
  name: 'instellingen-delivery-date-exceptions-uuid'
}]

const router = new Router({
  mode: 'history',
  base: '/admin',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',

  routes: [
    ...routes
  ]
})

export default router
