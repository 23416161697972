import Vue from 'vue'
import { Store } from 'vuex'
import axios from 'axios'
import store from './store'
import cookieUniversal from 'cookie-universal'

import '@topgeschenken/webshop-admin/plugins/vue'
import '@topgeschenken/webshop-admin/plugins/toast'
import '@topgeschenken/webshop-admin/plugins/moment'

import EnvironmentPlugin from '@topgeschenken/front-library/plugins/EnvironmentPlugin'
import StoragePlugin from '@topgeschenken/front-library/plugins/StoragePlugin'
import ApiPlugin from '@topgeschenken/front-library/plugins/ApiPlugin'
import GraphQlPlugin from '@topgeschenken/front-library/plugins/GraphQlPlugin'
import ApiDataProvider from '@topgeschenken/front-library/plugins/ApiDataProvider'
import AuthPlugin from '@topgeschenken/front-library/plugins/AuthPlugin'
import AccountPlugin from '@topgeschenken/front-library/plugins/AccountPlugin'
import MercureListener from '@topgeschenken/front-library/plugins/MercureListener'
import MercureMessageHandlerPlugin from '@topgeschenken/front-library/plugins/MercureMessageHandlerPlugin'
import VueSsePlugin from '@topgeschenken/front-library/plugins/vue-sse'

const urlSearchParams = new URLSearchParams(window.location.search)
const query = Object.fromEntries(urlSearchParams.entries())

const context = {
  $config: {},
  $axios: axios,
  app: {
    store,
    router: {
      push (path) {
        window.location.href = path
      }
    }
  },
  store,
  redirect (path) {
    window.location.href = path
  },
  $cookies: cookieUniversal(),
  route: {
    query: { ...query, redirectPath: window.location.pathname },
    fullPath: window.location.pathname,
    meta: {
      requireAuth: window.USE_API_AUTH !== false
    }
  }
}

Store.prototype[`$axios`] = axios

export const injectPlugin = function (name, callback, options = {}) {
  this[`$${name}`] = callback

  const MyPlugin = {}
  MyPlugin.install = function (Vue, options) {
    Vue[`$${name}`] = callback
    Vue.prototype[`$${name}`] = callback
    Store.prototype[`$${name}`] = callback
  }

  Vue.use(MyPlugin, options)
}.bind(context)

EnvironmentPlugin(context, injectPlugin)
StoragePlugin(context, injectPlugin)
VueSsePlugin(context, injectPlugin)
ApiPlugin(context, injectPlugin)
GraphQlPlugin(context, injectPlugin)
ApiDataProvider(context, injectPlugin)
AuthPlugin(context, injectPlugin)
AccountPlugin(context, injectPlugin)
MercureListener(context, injectPlugin)
MercureMessageHandlerPlugin(context, injectPlugin)

// Update VUE to handle on loggedin user-state
$(window).on('load', () => {
  if (context.$auth.isAuthenticated() === true) {
    context.$auth.triggerLoggedInEvent()
  }
})
