import { post } from 'axios';
import { standardXHRErrorHandler } from "../../standardXHRErrorHandler";
import {showNotification} from "../../toast";

/**
 * @param {string} reason
 * @param {string} type
 * @param {string} url
 */
export function fraudcheckActions({url}) {
    return post(url).then(({ data }) => {
        showNotification({ text: data.message });
    }).catch(standardXHRErrorHandler);
}
