export default function SelectAlternativeSupplierModal(props) {
    ContentWindow.apply(this, arguments);

    this.onTriggerSubmit = function(e) {
        e.preventDefault();

        var self = this;
        var selectedSupplier = window.alternativeSupplierModal.selectedSupplier;
        var title = '%supplier% toevoegen als alternatieve leverancier?';

        if(selectedSupplier === null) {
            swal({
                title: 'Selecteer eerst een leverancier',
                zindex: 9999
            });

            return false;
        }

        swal({
            'title': title.replace('%supplier%', selectedSupplier.name),
            type: 'question',
            showCancelButton: true,
            cancelButtonText: 'Annuleren',
            confirmButtonText: 'Toevoegen',
            reverseButtons: true
        }).then(function(result) {
            if(result === true) {
                self.renderAlternativeSupplierRow();
            }
        }.bind(this), function() {
            // nothing
        });
    };

    this.renderAlternativeSupplierRow = function() {
        var self = this;
        var supplierRowHtml = window.alternativeSupplierModal.supplierRowHtml;

        var panelSupplierSelection = $('.panel-supplier-selection');

        var tableElm = $('.panel-body > table', panelSupplierSelection);
        var tableBodyElm = $('.panel-body > table > tbody', panelSupplierSelection);
        var errorElm = $('.alert.no-results', panelSupplierSelection);

        var rowElm = $(supplierRowHtml);

        if(errorElm.length > 0) {
            tableBodyElm.html(rowElm);
            errorElm.remove();
        } else {
            tableBodyElm.append(rowElm);
        }

        tableElm.show();

        rowElm.find('input').trigger('click');

        self.hide();
    };

    this.open = function() {
        this.init();
        this.show();
        this.loading();

        var html = Object.assign(document.createElement('iframe'), {
            'id': 'select_alternative_supplier_frame',
            'src': props.url,
            'width': '100%',
            'height': '100%',
            'style': 'min-height: 300px; border: 0;'
        });

        this.setHtml(html);
        this.bindEvents();
    };

    this.init = function() {
        var toolbarButtons = [];

        toolbarButtons.push({
            id: 'select_supplier',
            position: 'right',
            label: 'Selecteer leverancier',
            order: 1,
            classNames: 'btn btn-primary',
            action: 'select-supplier'
        });

        this.options = $.extend(true, this.options, {toolbarButtons: toolbarButtons});

        swal.setDefaults({
            onOpen: function () {
                $('.swal2-container').css({
                    'z-index': 30000
                })
            }
        });

        this.initAlternativeSupplierModal();
    };

    this.initAlternativeSupplierModal = function() {
        window.alternativeSupplierModal = {
            selectedSupplier: null,
            supplierRowHtml: null
        }
    };

    this.bindEvents = function() {
        this.elm.on('click', '#select_supplier', this.onTriggerSubmit.bind(this))
    };
}

SelectAlternativeSupplierModal.prototype = ContentWindow.prototype;
SelectAlternativeSupplierModal.prototype.constructor = SelectAlternativeSupplierModal;
