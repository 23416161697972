function scrollParentToChild (parent, child) {
  const parentRect = parent.getBoundingClientRect()
  const parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth
  }
  const childRect = child.getBoundingClientRect()
  const isViewable = childRect.bottom <= parentRect.top + parentViewableArea.height

  if (!isViewable) {
    parent.scrollTop = (childRect.top + parent.scrollTop) - parentRect.top
  }
}

export { scrollParentToChild }
