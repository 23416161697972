import { initializeVueComponent } from '../../../plugins/vue'

const LOCK_PING_HEARTBEAT = 45000 // every 45 seconds

/**
 * Can be replaced with helper of frontends
 */
class Watcher {
    constructor(interval) {
        this.interval = interval
        this.watcher = null
    }

    clear = function () {
        if (this.watcher !== null) {
            clearTimeout(this.watcher)
            this.watcher = null
        }
    }

    init(callback = () => {}) {
        this.clear();
        if (typeof callback === 'function') {
            callback()
        }
        this.watcher = setTimeout(() => {
            this.init(callback)
        }, this.interval)
    }
}

export default class OrderLock {
    constructor() {
        this.vueInstance = initializeVueComponent()
        this.locked = null
        this.watcher = new Watcher(LOCK_PING_HEARTBEAT)
    }

    init ({ uuid }) {
        const store = this.vueInstance.$store
        const orderData = store.state.order.list[uuid]
        const promise = orderData
            ? Promise.resolve(orderData)
            : store.dispatch('order/fetchOne', { uuid }).then(response => response.data)

        this.unlockCurrent()
        promise.then((orderData) => {
            this.watcher.init(() => {
                this.lock(orderData)
            })
        })
    }

    lock(order = null) {
        this.locked = order

        this.vueInstance.$store.dispatch('order/lock', order)
    }

    unlock(order = null) {
        this.vueInstance.$store.dispatch('order/unlock', order)
    }

    unlockCurrent() {
        this.watcher.clear();
        if (this.locked !== null) {
            this.unlock(this.locked)
            this.locked = null
        }
    }
}
