import AddEditProduct from './add_edit_product';
import { showNotification } from '../../toast';

export default function EditOrder(url, parent, order) {
    this.contentWindow = new ContentWindow({
        width: window.innerWidth * 0.8 + 'px',
        zIndex: 2010,
        parent: parent,
        url: url,
        closeButtonLabel: 'Annuleren',
        toolbarButtons: [
            {
                id: 'order_save',
                position: 'right',
                label: 'Opslaan en sluiten',
                order: 1,
                classNames: 'btn btn-primary order-save',
                action: 'order-save'
            }
        ],
        hideCallbackOnSubmit: function() {
            this.openLastOrderInformation();
        }.bind(this)
    });

    this.order = order;

    this.supplierRemark = null;

    this.edit(url);
}

EditOrder.prototype.confirmModification = function (callback) {
    const self = this;
    const vm = $(this.contentWindow.elm);

    let supplierRemarkElm = $('#order_supplierRemark', vm);

    if(supplierRemarkElm.length === 0) {
        supplierRemarkElm = $('#composition_order_supplierRemark', vm);
    }

    const html = `
        <div class="fields" style="font-size: 15px;">
            <div class="field">
                <input id="confirm_phone_contact" type="checkbox">
                <label for="confirm_phone_contact">Er is telefonisch overleg geweest met de leverancier</label>
            </div>             
            <div class="field" style="display: none; margin-top: 1rem">       
                <label><strong>Aanvullen opmerkingen voor leverancier:</strong></label>
                <textarea id="confirm_supplier_remark" placeholder="Type hier een opmerking voor de leverancier" style="display: block; width: 100%; border-color: #ddd; min-height: 250px;"></textarea>    
            </div>
        </div>        
    `;

    return swal({
        type: 'warning',
        title: 'Order toch bewerken?',
        html: html,
        showCancelButton: true,
        confirmButtonText: 'Bewerken',
        reverseButtons: true,
        onOpen: function () {
            $('.swal2-container').css({
                'z-index': 9001
            });

            const confirmSupplierRemarkElm = $('#confirm_supplier_remark');

            $('#confirm_phone_contact').on('change', function() {
                const elm = $(this);
                const isChecked = elm.prop('checked');

                confirmSupplierRemarkElm.val(supplierRemarkElm.val());
                confirmSupplierRemarkElm.closest('.field').toggle(isChecked);
            });

        },
        preConfirm: function (values) {
            return new Promise((resolve, reject) => {
                const isChecked = $('#confirm_phone_contact').prop('checked');
                if (isChecked) {
                    resolve();
                } else {
                    reject('Bevestiging is verplicht.');
                }
            });
        }
    }).then(() => {
        const confirmSupplierRemarkElm = $('#confirm_supplier_remark');
        const text = confirmSupplierRemarkElm.val();

        supplierRemarkElm.val(text);
        self.supplierRemark = text;

        if (typeof callback === 'function') {
            callback();
        }
    }).catch(swal.noop);
};

EditOrder.prototype.can = function(action, callback) {
    const orderEditElm = $("#order_edit", this.contentWindow.elm);
    const canEditOrderComposition = !!orderEditElm.data('can-edit-order-composition');
    const canEditDeliveryAddress = !!orderEditElm.data('can-edit-delivery-address');
    const canEditDeliveryDate = !!orderEditElm.data('can-edit-delivery-date');
    const isLocked = !!orderEditElm.data('edit-is-locked');

    if(action === 'edit_order_composition' && !canEditOrderComposition && !isLocked) {
        this.confirmModification(callback);
        return;
    }

    if(action === 'edit_delivery_address' && !canEditDeliveryAddress && !isLocked) {
        this.confirmModification(callback);
        return;
    }

    if(action === 'edit_delivery_date' && !canEditDeliveryDate && !isLocked) {
        this.confirmModification(callback);
        return;
    }

    if(typeof callback === 'function') {
        callback();
    }
};

EditOrder.prototype.edit = function (url) {
    var self = this;

    this.url = url;
    this.contentWindow.empty().show();

    $.get(url).done(function (data) {
        self.contentWindow.setHtml(data);

        self.initialize();
    }).fail(function () {

    });
};

EditOrder.prototype.initialize = function () {
    cms.getElements().initElementDate();

    this.orderLinesIndex = $('#order_overview').find('.order-line').length - 1;
    this.lastGeneratedUuid = null;

    $('[data-element="metadata"]', this.contentWindow.elm).parent('td').addClass('metadata');

    this.bindEvents();
};

EditOrder.prototype.toggleDisableOrderEdit = function(disabled) {
    var orderOverviewElm = $('#order_overview');

    // Force display of add/edit product button
    $('#order_edit_add_product').toggle(!disabled);
    $('#order_edit .line-delete').toggle(!disabled);

    $('input[data-element="quantity"]', orderOverviewElm).prop('disabled', disabled);
    $('select[data-element="product"]', orderOverviewElm).prop('disabled', disabled);
    $('input[data-element="discount-amount"]', orderOverviewElm).prop('disabled', disabled);
    $('textarea[data-element="metadata"]', orderOverviewElm).prop('disabled', disabled);

    $('input[name="order[disableOrderEdit]"]', this.contentWindow.elm).val(disabled ? '1' : '0');

    orderOverviewElm.toggleClass('disabled', disabled);
};

EditOrder.prototype.editComposition = function(result) {
    const self = this;
    const btnElm = $('#change_composition', this.contentWindow.elm);
    const url = btnElm.data('edit-composition-url');
    const contentWindow = $(this.contentWindow.elm);

    $.ajax({
        url: url,
    }).then(function(html) {

        this.contentWindow.setHtml(html);
        this.bindEvents();

        const saveButton = $('.content-window-toolbar a[data-action="order-save"]');
        const orderNumberContainer = $('#order_number', contentWindow.elm);

        orderNumberContainer
        .attr('data-old-number', orderNumberContainer.html())
        .html(result.number)
        .append('<span class="label draft heading-text bg-grey-300">Concept</span>')
        .nextAll($('.label:not(.draft)'))
        .remove();

        $(`<div class="save-notice">Let op: Na het opslaan zal order ${orderNumberContainer.attr('data-old-number')} worden geannuleerd, order ${result.number} wordt toegevoegd.</div>`).insertBefore(saveButton);

        saveButton
            .text('Gewijzigde samenstelling opslaan')
            .removeClass('btn-primary')
            .addClass('bg-success');

        // Support both forms
        if(self.supplierRemark) {
            $("#order_supplierRemark", this.contentWindow.elm).val(self.supplierRemark);
            $("#composition_order_supplierRemark", this.contentWindow.elm).val(self.supplierRemark);
            self.supplierRemark = null;
        }
    }.bind(this))
};

EditOrder.prototype.enablePanelFields = function(panelElm) {
    const fields = $("input, select, textarea", panelElm);

    fields.removeAttr('readonly');
    fields.removeAttr('disabled');
};

EditOrder.prototype.bindEvents = function () {
    var self = this;
    var disabled = $('#change_composition:visible').length > 0;
    var contentWindow = $(this.contentWindow.elm);

    this.initializeAddressCheck();
    this.initializeSwitchery();
    this.toggleDisableOrderEdit(disabled);
    this.addressLookup();


  contentWindow.unbind()
    .on('click', 'button[data-action=select-supplier]', function (e) {
        e.preventDefault();

        $('#order_process_supplier', self.contentWindow.elm).val($(this).data('id'));

        $('form', self.contentWindow.elm).trigger('submit');
    });

    contentWindow.on('click', '.delivery-information-toggle', function () {
        $(this).closest('.delivery-information').toggleClass('open');
    });

    contentWindow.on('keyup change', '.element-quantity', function (e) {
        var elm = $(this);
        var row = elm.closest('tr');
        var previousValue = $(this).prop('defaultValue');
        var orderLineId = row.data('id');
        var value = e.target.value;
        var childRows = $(`tr[data-parent="${orderLineId}"]`);

        if ($(this).val() <= 0) {
            value = previousValue;
            $(this).val(value);
        }

        // Update also child lines
        childRows.each(function() {
            $(this).find('.element-quantity').val(value);
        });

        self.calculateTotals();
    });

    contentWindow.on('keyup change', '.element-price', function (e) {
        var priceExclElm = $(this);
        var orderLineRowElm = priceExclElm.closest('tr');
        var vatElm = $('.column-vat-percentage', orderLineRowElm);
        var priceInclElm = $('.element-price-incl', orderLineRowElm);

        // Calculate
        var priceExcl = priceExclElm.val().replace(',', '.');
        var vatPercentage = parseInt(vatElm.text().replace('%', ''));
        var priceIncl = priceExcl * (100 + vatPercentage) / 100;

        // Display price
        $(priceInclElm).val(priceFilter(priceIncl, 2));

        if (e.type === 'change') {
            $(priceExclElm).val(priceFilter(priceExcl, 3));
        }

        self.calculateTotals();
    });

    contentWindow.on('keyup change', '.element-price-incl', function (e) {
        var priceInclElm = $(this);
        var orderLineRowElm = priceInclElm.closest('tr');
        var priceExclElm = $('.element-price', orderLineRowElm);
        var vatElm = $('.column-vat-percentage', orderLineRowElm);

        // Calculate
        var priceIncl = priceInclElm.val().replace(',', '.');
        var vatPercentage = parseInt(vatElm.text().replace('%', ''));
        var priceExcl = priceIncl / (100 + vatPercentage) * 100;

        // Display price
        if (e.type === 'change') {
            $(priceInclElm).val(priceFilter(priceIncl, 2));
        }

        $(priceExclElm).val(priceFilter(priceExcl, 3));

        self.calculateTotals();
    });

    contentWindow.on('click', '#change_composition', function (e) {
        e.preventDefault();

        self.can('edit_order_composition', () => {
            swal({
                type: 'question',
                title: 'Weet je het zeker?',
                text: 'Zodra de samenstelling voor deze order wijzigt wordt deze geannuleerd en wordt er een nieuwe order aangemaakt. Wijzigingen in het huidige formulier gaan verloren.',
                showCancelButton: true,
                cancelButtonText: 'Annuleren',
                confirmButtonText: 'Ja, aanpassen',
                reverseButtons: true,
                preConfirm: () => {
                    return fetch(self.url + '?get-next-available-number', {credentials: 'same-origin'})
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(response.statusText)
                        }

                        return response.json()
                    })
                    .catch((error) => {
                        swal('Foutmelding', error, 'error');
                    });
                }
            })
            .then(this.editComposition.bind(this))
            .catch(swal.noop);
        });
    }.bind(this));

    contentWindow.on('click', 'div[data-unlock-action]', function (e) {
        e.preventDefault();

        const unlockElm = $(e.currentTarget);
        const panelElm = unlockElm.closest('.panel');
        const unlockAction = unlockElm.data('unlock-action');

        this.can(unlockAction, () => {
            unlockElm.remove();

            // Check the hidden input (not visuel displayed)
            $('[type=checkbox]', panelElm.find('.panel-heading')).prop( "checked", true );

            this.enablePanelFields(panelElm);
        })
    }.bind(this));

    contentWindow.on('click', 'td.line-delete > a', function (e) {
        e.preventDefault();

        var line = $(this).closest('tr');
        var id = line.attr('data-id');

        if (confirm('Weet je het zeker?')) {
            line.remove();

            var childLines = $(`[data-parent="${id}"]`, '#order_overview');

            $.each(childLines, function (i, elm) {
                $(elm).remove();
            });
        }
    });

    contentWindow
        .on('click', '#order_edit_add_product', function (e) {
            e.preventDefault();

            const elm = $(this);

            const props = Object.assign({}, self.getDefaultAddEditProductProps(), {
                orderId: elm.data('order-id')
            });

            new AddEditProduct(props);
        })
        .on('click', '.order-line-edit', function (e) {
            e.preventDefault();

            const elm = $(this);

            const productOrderLine = elm.closest('.order-line');
            const orderLineId = productOrderLine.data('id');
            const cardOrderLine = $(`.order-line[data-parent="${orderLineId}"][data-card="true"]`);
            const personalizationOrderLine = $(`.order-line[data-parent="${orderLineId}"][data-personalization="true"]`);
            const letterOrderLine = $(`.order-line[data-parent="${orderLineId}"][data-letter="true"]`);
            const props = Object.assign({}, self.getDefaultAddEditProductProps(), {
                orderId: elm.data('order-id'),
                editMode: true,
                initializeData: {
                    product: self.getRowData('product', productOrderLine),
                    card: self.getRowData('card', cardOrderLine),
                    letter: self.getRowData('letter', letterOrderLine),
                    personalization: self.getRowData('personalization', personalizationOrderLine),
                },
                jQueryElements: {
                    product: productOrderLine.length > 0 ? productOrderLine : null,
                    card: cardOrderLine.length > 0 ? cardOrderLine : null,
                    letter: letterOrderLine.length > 0 ? letterOrderLine : null,
                    personalization: personalizationOrderLine.length > 0 ? personalizationOrderLine : null,
                }
            });

            new AddEditProduct(props);
        })
        .on('click', '[data-action=order-save]', function (e) {

            e.preventDefault();

            var elm = $(this);
            var form = elm.parents('.content-window').find('form');

            form.validate();

            if (form.isValid()) {
                elm.attr('disabled', 'disabled');
                elm.html(`<i class="fa fa-spinner fa-spin"></i> ${elm.html()}`);

                form.trigger('submit');
            }
        });

    document
        .getElementById('order_edit')
        .addEventListener('product:add_update_order_line', function (e) {
            const {productData, jQueryElements} = e.detail;
            const productLine = self.addUpdateOrderLine(productData.product, 'product', jQueryElements.product || null);
            const productTypes = ['card', 'personalization', 'letter'];

            for(const key in productTypes) {
                if(productTypes.hasOwnProperty(key)) {
                    const type = productTypes[key];
                    const data = productData[type] || null;
                    const jQueryElm = jQueryElements[type] || null;

                    if (data) {
                        self.addUpdateOrderLine(
                            data,
                            type,
                            jQueryElm,
                            productLine
                        );
                    } else if(jQueryElm && !data) {
                        jQueryElm.remove();
                    }
                }
            }

            self.calculateTotals();
        }
    );
};

EditOrder.prototype.renderNewLine = function (item, type, productLine) {
    this.orderLinesIndex++;
    this.lastGeneratedUuid = generateUuid();

    const parentId = productLine ? productLine.data('id') : null;
    const orderOverviewTableElm = $('#order_overview');
    const index = this.orderLinesIndex;
    const prototype = orderOverviewTableElm.attr('data-prototype');
    const prototypeHtml = prototype.replace(/__name__/g, index);
    const rowHtml = `
        <tr 
        class="order-line${parentId ? ' child-order-line' : ''}"
        data-id="${this.lastGeneratedUuid}"
        ${parentId ? `data-parent="${parentId}"` : ''}
        ${type === 'card' ? `data-card="true"` : ''}
        ${type === 'letter' ? `data-letter="true"` : ''}
        ${type === 'personalization' ? `data-personalization="true"` : ''}
        data-index="${this.orderLinesIndex}"
        >
            ${prototypeHtml}
        </tr>
    `;

    const jQueryElm = $(rowHtml);
    const productElement = jQueryElm.find('[data-element="product"]');
    const deliveryCostsLine = orderOverviewTableElm.find('.order-line[data-transport-type="1"]');

    // Add actions column
    let actionDeleteLinkHtml = '';
    let actionEditLinkHtml = '';

    if (type === 'product') {
        actionDeleteLinkHtml = `<a href="#" data-popup="tooltip" data-placement="right" data-original-title="Regel verwijderen" class="order-line-delete">
            <i class="fa fa-times" aria-hidden="true"></i>
        </a>`;

        actionEditLinkHtml = `<a href="#" data-order-id="${orderOverviewTableElm.data('order-id')}" data-popup="tooltip" data-placement="right" data-original-title="Product bewerken" class="order-line-edit-link order-line-edit">
            <i class="fa fa-pencil" aria-hidden="true"></i>
        </a>`;
    }
    const actionDeleteColumnHtml = `<td class="column-delete line-delete align-middle">${actionDeleteLinkHtml}</td>`;
    const actionEditColumnHtml = `<td class="column-edit line-edit align-middle text-center">${actionEditLinkHtml}</td>`;

    $(actionDeleteColumnHtml).prependTo(jQueryElm);
    $(actionEditColumnHtml).insertAfter(jQueryElm.find('td:eq(1)'));

    // Update product selection / column position
    $(productElement.closest('td')).insertAfter(jQueryElm.find('td:eq(2)'));

    productElement.html(`<option value="${item.id}">${item.name}</option>`);
    productElement.toggleClass('element-product', true);

    // Add vat column
    const vatColumnHtml = `<td class="column-vat-percentage"></span>`;
    $(vatColumnHtml).insertAfter($(jQueryElm).find('td:eq(5)'));

    // Add total column
    const totalColumnHtml = `<td class="column-price-total line-price-total text-right">
        <span class="pull-left">&euro;</span>
        <span class="text"></span>
    </td>`;

    $(totalColumnHtml).insertBefore($(jQueryElm).find('td:last'));

    const quantityElm = jQueryElm.find('[data-element="quantity"]');

    if (parentId && type !== 'card') {
        quantityElm.prop('readonly', true);
    }

    if (productLine) {
        quantityElm.val(productLine.find('[data-element="quantity"]').val());
    } else {
        quantityElm.val(1);
    }

    // Insert to correct place.
    if (productLine) {
        jQueryElm.insertAfter(productLine);
    } else if(deliveryCostsLine.length > 0) {
        jQueryElm.insertBefore(deliveryCostsLine);
    } else {
        $('tbody', orderOverviewTableElm).append(jQueryElm);
    }

    return jQueryElm;
};

/**
 *
 * @param type
 * @param jQueryElm
 * @returns {{priceIncl: number, metadata: any, price: number, vatGroupId: number | null, name: *, vat: number, id: number | null}|{}}
 */
EditOrder.prototype.getRowData = function (type, jQueryElm) {
    if(jQueryElm.length === 0) {
        return {};
    }

    const productElm = $('[data-element="product"] option:selected', jQueryElm);
    const vatGroupElm = $('[data-element="vat"] option:selected', jQueryElm);
    const priceElm = jQueryElm.find('[data-element="price"]');
    const priceInclElm = jQueryElm.find('[data-element="price-incl"]');
    const metadataElm = jQueryElm.find('[data-element="metadata"]');
    const vat = jQueryElm.find('td.column-vat-percentage').text().replace('%', '');
    const vatGroupId = parseInt(jQueryElm.find('td.column-vat-percentage').data('vat-group-id')) || null;
    const id = parseInt(productElm.val()) || null;
    const price = parseFloat(priceElm.val().replace(',', '.')) || 0;
    const priceIncl = parseFloat(priceInclElm.val().replace(',', '.')) || 0;

    return {
        id: id,
        name: productElm.text(),
        vatGroupId: vatGroupId,
        price: price,
        priceIncl: priceIncl,
        vat: parseInt(vat),
        metadata: JSON.parse(metadataElm.val())
    };
};

EditOrder.prototype.getDefaultAddEditProductProps = function() {
    const orderOverview = $('#order_overview');
    const props = {};

    props.availableCardsUrl = orderOverview.data('available-cards-url');
    props.availablePersonalizationsUrl = orderOverview.data('available-personalizations-url');
    props.availableLettersUrl = orderOverview.data('available-letters-url');
    props.addDesignUrl = orderOverview.data('add-design-url');
    props.editDesignUrl = orderOverview.data('edit-design-url');
    props.searchProductUrl = orderOverview.data('search-product-url');
    props.productInfoUrl = orderOverview.data('product-info-url');
    props.designerPreviewUrl = orderOverview.data('designer-preview-url');

    return props;
};

EditOrder.prototype.updateRowData = function (jQueryElm, item, type, productLine) {
    const price = priceFilter(item.price, 3);
    const priceIncl =  priceFilter(item.priceIncl, 2);
    const productSelectedOptionElm = jQueryElm.find('[data-element="product"] option');

    productSelectedOptionElm.attr('value', item.id);
    productSelectedOptionElm.text(item.name);

    jQueryElm.find('[data-element="vat"]').val(item.vatGroupId);
    jQueryElm.find('[data-element="price"]').val(price);
    jQueryElm.find('[data-element="price-incl"]').val(priceIncl);
    jQueryElm.find('.column-vat-percentage').html(`${item.vat}%`);

    if(productLine) {
        item.metadata.parentIndex = productLine.data('index');
    }

    jQueryElm.find('[data-element="metadata"]').val(JSON.stringify(item.metadata));
};

EditOrder.prototype.updateRowColumnStates = function (jQueryElm) {
    var disabled = $('#change_composition:visible').length > 0;

    jQueryElm.find('[data-element="quantity"]').prop('disabled', disabled);
    jQueryElm.find('[data-element="product"]').prop('disabled', disabled);
    jQueryElm.find('[data-element="discount-amount"]').prop('disabled', disabled);
    jQueryElm.find('[data-element="metadata"]').prop('disabled', disabled);
};

EditOrder.prototype.addUpdateOrderLine = function (data, type, jQueryElm, productLine) {
    if (jQueryElm === null) {
        jQueryElm = this.renderNewLine(data, type, productLine);
    }

    this.updateRowData(jQueryElm, data, type, productLine);
    this.updateRowColumnStates(jQueryElm);

    return jQueryElm;
};

EditOrder.prototype.initializeAddressCheck = function () {
    var self = this;
    var originalAddress = self.addressToHtml();

    $(':input[name^="order[deliveryAddress]"]', this.contentWindow.elm).on('change', function () {
        var modifiedAddress = self.addressToHtml();

        swal({
            title: 'Het afleveradres is gewijzigd.',
            text: '<p>Is deze wijziging van toepassing op deze order of dient deze wijziging ook op alle toekomstige bestellingen te worden doorgevoerd?!</p>\
            <table style="text-align: left; width: 100%;">\
            <tr><th><strong>Oorspronkelijk adres</strong></th><th><strong>Gewijzigd adres</strong></th></tr>\
            <tr><td>' + originalAddress + '</td><td>' + modifiedAddress + '</td></tr>\
            </table>',
            type: 'warning',
            html: true,
            showCancelButton: true,
            confirmButtonColor: '#2196F3',
            confirmButtonText: 'Ja, adres bij klant ook wijzigen!',
            cancelButtonText: 'Nee',
            closeOnConfirm: false,
            closeOnCancel: false,
            animation: false
        });
    });
};

EditOrder.prototype.addressToHtml = function () {
    var html = '';

    html += $('input[name="order[deliveryAddress][companyName]"]').val() + '<br />';
    html += $('input[name="order[deliveryAddress][attn]"]').val() + '<br />';
    html += $('input[name="order[deliveryAddress][street]"]').val() + ' ';
    html += $('input[name="order[deliveryAddress][number]"]').val() + '<br />';
    html += $('input[name="order[deliveryAddress][postcode]"]').val() + ' ';
    html += $('input[name="order[deliveryAddress][city]"]').val() + '<br />';
    html += $('select[name="order[deliveryAddress][country]"] option:selected').text() + '<br />';
    html += $('input[name="order[deliveryAddress][phoneNumber]"]').val() + '<br />';

    return html;
};

EditOrder.prototype.initializeSwitchery = function () {
    var self = this;

    var input = $('input.switchery', this.contentWindow.elm);

    input.on('change', function () {
        var state = $(this).is(':checked');

        $('.total-line-vat, .total-line-price-excl').toggle(!state);
        $('.total-line-placeholder').toggle(!state);

        self.calculateTotals(state);
    }).trigger('change');

    new Switchery(input.get(0));
};

EditOrder.prototype.calculateTotals = function (isIncl) {
    if (!isIncl) {
        isIncl = $('input.switchery', this.contentWindow.elm).is(':checked');
    }

    const quantityElm = $('.element-quantity', this.contentWindow.elm);

    if(quantityElm.length === 0) {
        return;
    }

    let totalPrice = 0;
    let totalPriceIncl = 0;

    $.each($('#order_overview tr.order-line'), function (i, elm) {
        const quantity = $('.element-quantity', $(elm)).val();
        const price = $('.element-price', $(elm)).val();
        const priceIncl = $('.element-price-incl', $(elm)).val();
        const lineTotal = parseFloat(price.replace(',', '.')) * quantity;
        const lineTotalIncl = parseFloat(priceIncl.replace(',', '.')) * quantity;

        totalPrice += lineTotal;
        totalPriceIncl += lineTotalIncl;

        $(elm).find('td.line-price-total span.text').html(priceFilter(
            isIncl ? lineTotalIncl : lineTotal,
            isIncl ? 2 : 3
        ));
    }.bind(this));

    $('.total-line-price-excl span.text', '#order_overview').html(priceFilter(totalPrice, 3) || 0);
    $('.total-line-vat span.text', '#order_overview').html(priceFilter(totalPriceIncl - totalPrice, 2) || 0);
    $('.total-line-price-incl span.text', '#order_overview').html(priceFilter(totalPriceIncl, 2) || 0);
};

EditOrder.prototype.openLastOrderInformation = function () {
    const urlParts = this.url.split('/');
    if (typeof urlParts !== undefined) {
        this.order.orderId = urlParts.pop();
    }
};

EditOrder.prototype.addressLookup = () => {
    const triggerElement = document.getElementById('address-autocomplete-lookup')
    const queryElement = document.getElementById('address-autocomplete-query')
    const copyElement = document.getElementById('address-autocomplete-copy')
    const addressStreetInput = document.getElementById('order_deliveryAddressStreet')
    const addressNumberInput = document.getElementById('order_deliveryAddressNumber')
    const addressNumberAdditionInput = document.getElementById('order_deliveryAddressNumberAddition')
    const addressPostcodeInput = document.getElementById('order_deliveryAddressPostcode')
    const addressCityInput = document.getElementById('order_deliveryAddressCity')

    const country = document.querySelectorAll('[data-country-code]')
    const contextElement = document.createElement('input')
    contextElement.setAttribute('type', 'hidden')
    contextElement.setAttribute('id', 'address-autocomplete-context')
    contextElement.value = country[0].dataset.countryCode === 'BE' ? 'bel' : 'nld'
    triggerElement.appendChild(contextElement)

    if (triggerElement) {
        document.dispatchEvent(new Event('addressAutocomplete:loaded'))
        triggerElement.addEventListener('click', (e) => {
            e.preventDefault()
            queryElement.value = triggerElement.dataset.addressLookup
            queryElement.focus()
        })
    }

    if (copyElement) {
        copyElement.addEventListener('click', (e) => {
            e.preventDefault()
            addressStreetInput.value = copyElement.dataset.street
            addressNumberInput.value = copyElement.dataset.number
            addressNumberAdditionInput.value = copyElement.dataset.numberAddition
            addressPostcodeInput.value = copyElement.dataset.postcode
            addressCityInput.value = copyElement.dataset.city
            showNotification({ text: 'Adres aangepast' })
        })
    }
}
