import Vue from 'vue'
import Vuetify from 'vuetify'
import './../assets/scss/vuetify.scss'

import nl from 'vuetify/src/locale/nl'

const options = {
  // customVariables: ['~/assets/variables.scss'],
  lang: {
    locales: { nl },
    current: 'nl'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        "primary": "#26a69a",
        "secondary": "#263238",
        "accent": "#f5f5f5",
        "error": "#ef5350",
        "info": "#2196F3",
        "success": "#4CAF50",
        "warning": "#FB8C00"
      },
      dark: {
        "primary": "#26a69a",
        "secondary": "#263238",
        "accent": "#f5f5f5",
        "error": "#ef5350",
        "info": "#2196F3",
        "success": "#4CAF50",
        "warning": "#FB8C00"
      }
    }
  }
};

Vue.use(Vuetify)

export default new Vuetify(options)
