export default function SendInvoice(parameters) {
    SendInvoice.open(parameters);
}

SendInvoice.open = function (parameters) {
    swal({
        title: 'Verstuur kopie factuur',
        text: 'Controleer het onderstaande e-mailadres voordat je de kopie verstuurd!',
        type: 'info',
        input: 'email',
        inputValue: parameters.email,
        inputClass: 'swal-send-invoice-input',
        showCancelButton: true,
        confirmButtonText: 'Versturen',
        showLoaderOnConfirm: true,
        cancelButtonText: 'Annuleren',
        allowOutsideClick: false,
        customClass: 'swa-send-invoice',
        reverseButtons: true,
        allowEnterKey: false,
        preConfirm: function (email) {
            return new Promise(function (resolve, reject) {
                $.ajax({
                    url: parameters.url,
                    method: 'POST',
                    dataType: 'json',
                    data: {
                        email: email
                    }
                }).done(function (response) {
                    if (response) {
                        if (response.error) {
                            reject('Er is een fout opgetreden, probeer het later opnieuw.');

                            return;
                        }
                    }

                    resolve();
                }).fail(function (response) {
                    reject('Er is een fout opgetreden, probeer het later opnieuw.');
                });
            })
        }
    }).then(function (email) {
        swal({
            type: 'success',
            title: 'Kopie factuur is verstuurd',
            html: 'De kopie factuur is succesvol verstuurd naar: ' + email
        }).catch(swal.noop);
    }).catch(swal.noop);

    const swaSendInvoiceElm = $('.swa-send-invoice');

    swaSendInvoiceElm
        .closest('.swal2-container')
        .css({'z-index': 5000})
    ;

    swaSendInvoiceElm
        .css({'z-index': 5001})
    ;
};
