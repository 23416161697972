export default function EditProcess(url) {
    this.contentWindow = new ContentWindow({
        width: window.innerWidth * 0.7 + 'px',
        zIndex: 2010,
        closeButtonLabel: 'Venster sluiten',
        parent: Order.contentWindow,
        toolbarButtons: [
            {
                position: 'right',
                label: 'Opslaan',
                order: 1,
                classNames: 'btn btn-primary',
                action: 'edit_process'
            }
        ]
    });

    this.open(url);
}

EditProcess.prototype.open = function (url) {
    var self = this;

    this.url = url;
    this.contentWindow.empty().show();

    $.get(url).done(function (data) {
        self.contentWindow.setHtml(data);

        self.initialize();
    });
};

EditProcess.prototype.initialize = function () {
    this.bindEvents();
    this.initializeTooltips();
    this.initializePopovers();
};

EditProcess.prototype.bindEvents = function () {
    var self = this;
    var contentWindowElm = $(this.contentWindow.elm);

    new CollectionCalcVat().init(contentWindowElm.find('.collection-items'));

    contentWindowElm.unbind();

    $('a[data-action="edit_process"]', this.contentWindow.elm).on('click', function (e) {
        e.preventDefault();

        swal({
            type: 'question',
            title: 'Reden van aanpassing',
            input: 'textarea',
            showCancelButton: true,

            onOpen: function () {
                $('.swal2-container').css({
                    'z-index': 2011
                })
            },
            inputValidator: function (input) {
                return new Promise(function (resolve, reject) {
                    if (input) {
                        resolve()
                    } else {
                        reject('Reden veld mag niet leeg zijn!');
                    }
                })
            }
        }).then(function (input) {
            $('#supplier_order_reason', contentWindowElm).val(input);
            $('form', contentWindowElm).submit();
        }, function (dismiss) {
            $('#supplier_order_reason', contentWindowElm).val('');
        });
    });
};

EditProcess.prototype.initializePopovers = function () {
    $('[data-toggle=popover]', this.contentWindow.elm).popover()
};

EditProcess.prototype.initializeTooltips = function () {
    $(this.contentWindow.elm).tooltip({
        selector: '[data-popup=tooltip]'
    });
};
