const uuidRegex = '[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}';

export const isUUID = (uuid) => {
  const regex = new RegExp('^' + uuidRegex + '$', 'i');

  return regex.test(uuid) === true;
};

export const grabUuidFromString = (string) => {
  const regex = new RegExp(uuidRegex, 'gm');

  let uuid = null, m;

  while ((m = regex.exec(string)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    // The result can be accessed through the `m`-variable.
    m.forEach((match) => {
      uuid = match;
    });
  }

  return uuid;
};

window.isUUID = isUUID;
window.grabUuidFromString = grabUuidFromString;
