import Order from './../order/order';
import CancelOrder from './actions/cancel_order';
import FastForward from './actions/fast_forward';
import ProcessOrderModal from './../modals/process_order_modal';

window.orderList = null;

export default function OrderList() {
    this.elm = $('#order_list');

    this.init = function() {
        this.bindEvents();
    };

    this.initDataTable = function() {
        this.elm.DataTable({
            'sDom': '',
            'paging': false,
            'ordering': false,
            'info': false,
            'columns': [
                {'width': '100px'},
                {'width': '150px'},
                null,
                null,
                null,
                null,
                {'width': '200px'},
                {'width': '150px'}
            ]
        });
    };

    this.bindEvents = function() {
        this.initDataTable();

        this.elm
            .on('click', 'button[data-event="get-order"]', function (e) {
                e.preventDefault();

                new Order(this.dataset.href, this.dataset.orderId);
            })
            .on('click', 'button[data-event="fast-forward"]', function (e) {
                e.preventDefault();

                return FastForward(this.dataset.href).then(result => {
                    if (result) {
                        this.dataset.originalTitle = 'Ik ga al!';
                        this.disabled = true;
                    }
                });
            })
            .on('click', 'button[data-event="process-order"]', function (e) {
                e.preventDefault();

                new ProcessOrderModal(this.dataset.href);
            })
            .on('click', 'button[data-event="cancel-order"]', function (e) {
                e.preventDefault();

                new CancelOrder(this.dataset.href);
            })
        ;
    };

    this.init();
}

$(function () {
    window.orderList = new OrderList();
});



