import { post } from 'axios'
import { Swal } from '../../../vue/utils/swal'
import { showNotification } from '../../toast'

/**
 * @param {string} code
 * @param {string} url
 */
export function checkVoucherCode ({ code, url }) {
  const formData = new FormData()
  formData.append('code', code)

  return post(url, formData)
    .then(() => {
      showNotification({ text: 'Korting toegepast' })
      return true
    })
    .catch((error) => {
      Swal.fire({
        title: 'Fout',
        html: 'De Korting is niet geldig',
        type: 'error',
        showCancelButton: false
      })
      return error
    })
}
