import PNotify from 'pnotify/lib/umd/PNotify'
import 'pnotify/lib/umd/PNotifyButtons'

/**
 * @param {string} text
 * @param {string} type
 * @param {object} props
 */
export function showNotification ({ text, type = 'success', ...props }) {
  PNotify.defaults.styling = 'bootstrap4'
  PNotify.defaults.icons = 'fontawesome4'
  PNotify.defaults.width = '260px'

  PNotify.modules.Buttons.defaults.closer = true
  PNotify.modules.Buttons.defaults.sticker = false

  return new PNotify({
    target: document.body,
    data: {
      textTrusted: true,
      text,
      type,
      ...props
    }
  })
}

/**
 * @deprecated use import method instead
 */
window.showNotification = showNotification
