export default function InternalRemark(url, id, options) {
    this.url = url;
    const container = $('#' + id);

    options.successCallback = function (data, contentWindow) {
        contentWindow.formChange = false;
        contentWindow.empty().hide();

        var user = 'Gegenereerd door systeem';
        if (data.hasOwnProperty('user')) {
            user = data.user;
        }

        if (data.hasOwnProperty('remark') && data.remark) {
            const internalRemarksElm = $(container).find('.internal-remarks');

            // Remove the 'no internal remarks available' message
            if ($(internalRemarksElm).find('.internal-remark').length === 0) {
                internalRemarksElm.empty();
            }

            internalRemarksElm.prepend(
                '<div class="internal-remark remark">' +
                '<div class="row">' +
                '<div class="col-xs-6">' +
                '<strong>' + user + '</strong>' +
                '</div>' +
                '<div class="col-xs-6 text-right">'
                + data.createdAt +
                '</div>' +
                '</div>' +
                '<div>'
                + data.remark +
                '</div>' +
                '</div>'
            );
        }
    };

    this.contentWindow = new ContentWindow(options);

    this.open();
};

InternalRemark.prototype.open = function () {

    var self = this;

    this.contentWindow.empty().show();
    this.contentWindow.loading();

    $.get(this.url).done(function (html) {
        self.contentWindow.setHtml(html);

        var textarea = $('textarea', self.contentWindow.elm);

        autosize(textarea, self.contentWindow.elm);

        textarea.focus();
        textarea.get(0).selectionStart = textarea.get(0).selectionEnd = textarea.val().length;
    });
};

$(function () {
    $(document).on('click', 'button.delete-remark', function (e) {
        const elm = $(this);
        const remarkId = $(this).data('id');

        swal({
            type: 'warning',
            title: 'Opmerking verwijderen',
            text: 'Weet je zeker dat je deze opmerking wilt verwijderen?',
            showCancelButton: true
        }).then((result) => {
            if (result) {
                $.ajax({
                    url: elm.attr('data-href'),
                    method: 'DELETE'
                }).then(() => {
                    const internalRemarksContainer = elm.closest('.internal-remarks');

                    elm.closest('.internal-remark').remove();

                    if (internalRemarksContainer.find('.internal-remark').length === 0) {
                        internalRemarksContainer.append('<em>Er zijn geen interne opmerkingen geplaatst.</em>');
                    }
                });
            }
        });
    });

    $('body').on('click', '[data-action="remark"]', function () {
        var elm = $(this);
        var url = elm.attr('data-url');
        var type = elm.attr('data-id');

        new InternalRemark(
            url,
            type,
            {
                width: '600px',
                zIndex: 2012,
            });
    });
});
